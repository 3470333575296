import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Tooltip, MenuItem } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import { AuthContext } from "../../context/authContext";

const Topbar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();
  const { currentUser, logout } = useContext(AuthContext);

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 0, display: "flex" }}>
            <IconButton size="large" aria-label="menu" onClick={handleOpenNavMenu} color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {currentUser && (currentUser.email === "rui.teixeira@heaboo.com" || currentUser.email === "eduardo.pereira@heaboo.com") && (
                <>
                  <MenuItem disabled>
                    <Typography textAlign="center" fontWeight={"bold"}>
                      ADMIN
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/tasks_manager")}>
                    <Typography textAlign="center">Gestor de Tarefas</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/register")}>
                    <Typography textAlign="center">Registar Empregado</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/management")}>
                    <Typography textAlign="center">Management</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/calendar_admin")}>
                    <Typography textAlign="center">Calendário ADMIN</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/inventory_admin")}>
                    <Typography textAlign="center">Inventário ADMIN</Typography>
                  </MenuItem>
                  <MenuItem disabled>
                    <Typography textAlign="center" fontWeight={"bold"}>
                      DEFAULT
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/activity")}>
                    <Typography textAlign="center">Registar Atividade</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/dashboard")}>
                    <Typography textAlign="center">Dashboard</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/calendar")}>
                    <Typography textAlign="center">Calendário</Typography>
                  </MenuItem>
                </>
              )}

              {currentUser && currentUser.email !== "rui.teixeira@heaboo.com" && currentUser.email !== "eduardo.pereira@heaboo.com" && (
                <>
                  <MenuItem onClick={() => navigate("/activity")}>
                    <Typography textAlign="center">Registar Atividade</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/dashboard")}>
                    <Typography textAlign="center">Dashboard</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/calendar")}>
                    <Typography textAlign="center">Calendário</Typography>
                  </MenuItem>
                </>
              )}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              onClick={() => navigate("/")}
              sx={{
                mr: -5,
                display: { xs: "flex", md: "flex" },
                justifyContent: { xs: "center", md: "center" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                flexGrow: 1,
                textAlign: "center",
              }}
            >
              ZW
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 0, ml: 1 }}>
            <IconButton onClick={() => window.location.reload()} color="inherit">
              <CachedOutlinedIcon sx={{ color: "white", width: 30, height: 30 }} />
            </IconButton>
            <IconButton onClick={() => navigate("/help")} color="inherit">
              <HelpOutlinedIcon sx={{ color: "white", width: 30, height: 30 }} />
            </IconButton>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <AccountCircleOutlinedIcon sx={{ color: "white", width: 30, height: 30 }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={() => logout()}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Topbar;
