import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography, Avatar, Container, Fade, Alert, AlertTitle } from "@mui/material";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import LoadingScreen from "../global/LoadingScreen";
import { AuthContext } from "../../context/authContext";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";


const Login = () => {
  const navigate = useNavigate();

  const pinSchema = yup.object().shape({
    email: yup.string().email("Endereço de email inválido!").required("O endereço de email é obrigatório!"),
    pin1: yup
      .number()
      .typeError("O PIN deve ser um número")
      .integer("O PIN deve ser um número inteiro")
      .positive("O PIN deve ser um número positivo")
      .min(0, "O PIN deve estar entre 0 e 9")
      .max(9, "O PIN deve estar entre 0 e 9")
      .required("O PIN é obrigatório!"),
    pin2: yup
      .number()
      .typeError("O PIN deve ser um número")
      .integer("O PIN deve ser um número inteiro")
      .positive("O PIN deve ser um número positivo")
      .min(0, "O PIN deve estar entre 0 e 9")
      .max(9, "O PIN deve estar entre 0 e 9")
      .required("O PIN é obrigatório!"),
    pin3: yup
      .number()
      .typeError("O PIN deve ser um número")
      .integer("O PIN deve ser um número inteiro")
      .positive("O PIN deve ser um número positivo")
      .min(0, "O PIN deve estar entre 0 e 9")
      .max(9, "O PIN deve estar entre 0 e 9")
      .required("O PIN é obrigatório!"),
    pin4: yup
      .number()
      .typeError("O PIN deve ser um número")
      .integer("O PIN deve ser um número inteiro")
      .positive("O PIN deve ser um número positivo")
      .min(0, "O PIN deve estar entre 0 e 9")
      .max(9, "O PIN deve estar entre 0 e 9")
      .required("O PIN é obrigatório!"),
    pin5: yup
      .number()
      .typeError("O PIN deve ser um número")
      .integer("O PIN deve ser um número inteiro")
      .positive("O PIN deve ser um número positivo")
      .min(0, "O PIN deve estar entre 0 e 9")
      .max(9, "O PIN deve estar entre 0 e 9")
      .required("O PIN é obrigatório!"),
    pin6: yup
      .number()
      .typeError("O PIN deve ser um número")
      .integer("O PIN deve ser um número inteiro")
      .positive("O PIN deve ser um número positivo")
      .min(0, "O PIN deve estar entre 0 e 9")
      .max(9, "O PIN deve estar entre 0 e 9")
      .required("O PIN é obrigatório!"),
  });

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const [email, setEmail] = useState(JSON.parse(localStorage.getItem("email")));
  const [start, setStart] = useState(true);
  const [errorMessageUser, setErrorMessageUser] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isNone, setIsNone] = useState(false);
  const { currentUser, login, logout } = useContext(AuthContext);

  useEffect(() => {
    if (email !== null) {
      setStart(false);
    } else {
      setStart(true);
    }
  }, [start]);

  useEffect(() => {
    if (currentUser && currentUser.email !== null) {
      navigate("/");
    } else {
      logout();
    }
  }, [currentUser]);


  const initialValuesPin = {
    email: "",
    pin1: null,
    pin2: null,
    pin3: null,
    pin4: null,
    pin5: null,
    pin6: null,
  };

  const handleFormSubmitPin = async (values) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `users/${values.email}`, config);

      if (response.data.data.length <= 0) {
        setErrorMessageUser(true);
        return;
      } else {
        let pin2 = Object.values(values).slice(-6).join("");
        console.log(pin2)
        let compareBody = {
          id: response.data.data[0].id,
          first_name: response.data.data[0].first_name,
          last_name: response.data.data[0].last_name,
          email: values.email,
          pin: parseInt(pin2),
        };

        console.log(compareBody)
        const response2 = await axios.post(process.env.REACT_APP_API_CALL + "users/verify", compareBody, config);

        console.log(response2.data)

        if (response2.data.success !== 1) {
          setErrorMessagePassword(true);
          setErrorMessageUser(false);
          return;
        } else {
          setIsSent(true);
          setIsNone(false);
          setErrorMessagePassword(false);
          setErrorMessageUser(false);
          setIsExpired(false);
          setTimeout(() => {
            login(compareBody);
          }, 1000);
        }
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        setSessionError(true);
      } else {
        setGeneralError(true);
        console.log(error);
        logout();
      }
    }
  };

  return (
    <>
      {/* <Topbar /> */}
      <Container component="main" maxWidth="xs">
        <Box display="flex" flexDirection="column" alignItems="center">
          {/* <Fade
            in={generalError}
            timeout={{ enter: 500, exit: 500 }}
            unmountOnExit={true}
            addEndListener={() => {
              window.scrollTo(0, 0);
              setTimeout(() => {
                setGeneralError(false);
                window.location.reload();
              }, 3000);
            }}
          >
            <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
              <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.general_error.title")}</AlertTitle>
              {t("login.alert_fields.general_error.description")}
            </Alert>
          </Fade> */}

          {/* <Fade
            in={sessionError}
            timeout={{ enter: 500, exit: 500 }}
            unmountOnExit={true}
            addEndListener={() => {
              window.scrollTo(0, 0);
              setTimeout(() => {
                setSessionError(false);
                navigate("/login");
                logout();
              }, 3000);
            }}
          >
            <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
              <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.session_error.title")}</AlertTitle>
              {t("login.alert_fields.session_error.description")}
            </Alert>
          </Fade> */}

          {/* <Fade
            in={isExpired}
            timeout={{ enter: 500, exit: 500 }}
            unmountOnExit={true}
            addEndListener={() => {
              setTimeout(() => {
                setIsExpired(false);
                window.location.reload();
              }, 3000);
            }}
          >
            <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
              <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.is_expired.title")}</AlertTitle>
              {t("login.alert_fields.is_expired.description")}
            </Alert>
          </Fade> */}

          {/* <Fade
            in={isNone}
            timeout={{ enter: 500, exit: 500 }}
            unmountOnExit={true}
            addEndListener={() => {
              setTimeout(() => {
                setIsNone(false);
                navigate(`/register/`);
                window.location.reload();
              }, 3000);
            }}
          >
            <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="warning">
              <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.is_none.title")}</AlertTitle>
              {t("login.alert_fields.is_none.description")}
            </Alert>
          </Fade> */}

          {/* <Fade
            in={isSent}
            timeout={{ enter: 500, exit: 500 }}
            unmountOnExit={true}
            addEndListener={() => {
              setTimeout(() => {
                setIsSent(false);
                window.location.reload();
              }, 2000);
            }}
          >
            <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
              <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.is_sent.title")}</AlertTitle>
              {t("login.alert_fields.is_sent.description")}
            </Alert>
          </Fade> */}

          {/* <Fade
            in={isComplete}
            timeout={{ enter: 500, exit: 500 }}
            unmountOnExit={true}
            addEndListener={() => {
              setTimeout(() => {
                setIsComplete(false);
                navigate("/register/");
                window.location.reload();
              }, 3000);
            }}
          >
            <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="success">
              <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.is_complete.title")}</AlertTitle>
              {t("login.alert_fields.is_complete.description")}
            </Alert>
          </Fade> */}

          <Fade
            in={errorMessageUser}
            timeout={{ enter: 500, exit: 500 }}
            unmountOnExit={true}
            addEndListener={() => {
              setTimeout(() => {
                setErrorMessageUser(false);
                // window.location.reload();
              }, 5000);
            }}
          >
            <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
              <AlertTitle sx={{ fontSize: "16px" }}>Erro!</AlertTitle>
              Endereço de email não registado. Por favor, contacte um administrador!
            </Alert>
          </Fade>

          {/* <Fade
            in={errorMessagePassword}
            timeout={{ enter: 500, exit: 500 }}
            unmountOnExit={true}
            addEndListener={() => {
              setTimeout(() => {
                setErrorMessagePassword(false);
                window.location.reload();
              }, 3000);
            }}
          >
            <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="error">
              <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.error_message_password.title")}</AlertTitle>
              {t("login.alert_fields.error_message_password.description")}
            </Alert>
          </Fade> */}

          {/* <Fade
            in={isRegistered}
            timeout={{ enter: 500, exit: 500 }}
            unmountOnExit={true}
            addEndListener={() => {
              setTimeout(() => {
                setIsRegistered(false);
                window.location.reload();
                logout();
              }, 3000);
            }}
          >
            <Alert variant="outlined" sx={{ fontSize: "16px" }} severity="warning">
              <AlertTitle sx={{ fontSize: "16px" }}>{t("login.alert_fields.is_registered.title")}</AlertTitle>
              {t("login.alert_fields.is_registered.description")}
            </Alert>
          </Fade> */}

          <>
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LoginOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h2">
              Entrar
            </Typography>
            <Formik
              onSubmit={handleFormSubmitPin}
              initialValues={initialValuesPin}
              isInitialValid={true}
              validateOnMount={true}
              validationSchema={pinSchema}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    fullWidth
                    variant="filled"
                    type="text"
                    label={"Endereço de Email"}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    inputProps={{ style: { fontSize: 16 } }}
                    InputLabelProps={{ style: { fontSize: 16 } }}

                    // sx={style}
                  />
                  <Typography component="h1" variant="h2" textAlign={"center"} marginTop={"50px"}>
                    Código Pin
                  </Typography>
                  <Box display="flex" justifyContent="space-between" width="100%" alignItems="center" mb={2}>
                    {[1, 2, 3, 4, 5, 6].map((index) => (
                      <TextField
                        key={index}
                        margin="normal"
                        variant="filled"
                        type="text" // Change type to "text"
                        inputMode="numeric" // Use inputMode numeric to display numeric keyboard
                        name={`pin${index}`}
                        onBlur={handleBlur}
                        onKeyDown={(e) => {
                          const allowedKeyCodes = [8, 9, 37, 39]; // Allow backspace, tab, left arrow, and right arrow
                          if (allowedKeyCodes.includes(e.keyCode)) return;
                          if (e.keyCode < 48 || e.keyCode > 57) {
                            e.preventDefault(); // Prevent input if it's not a number
                          }
                        }}
                        onChange={(e) => {
                          const inputValue = e.target.value.slice(-1); // Take only the last character
                          handleChange({ target: { name: e.target.name, value: inputValue } });
                          if (inputValue && index < 6) {
                            const nextField = document.querySelector(`#pin${index + 1}`);
                            if (nextField) {
                              nextField.focus();
                            }
                          }
                        }}
                        value={values[`pin${index}`]}
                        style={{ width: "15%", flexBasis: "15%" }}
                        inputProps={{ maxLength: 1, inputMode: "numeric" }}
                        id={`pin${index}`}
                      />
                    ))}
                  </Box>

                  {Object.keys(errors).some((key) => key.startsWith("pin") && touched[key] && errors[key]) && (
                    <Alert variant="outlined" severity="error">
                      {
                        Object.keys(errors)
                          .filter((key) => key.startsWith("pin") && touched[key] && errors[key])
                          .map((key) => errors[key])[0]
                      }
                    </Alert>
                  )}

                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    fullWidth
                    sx={{
                      mt: 3,
                      mb: 2,
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold">
                      Login
                    </Typography>
                  </Button>
                </form>
              )}
            </Formik>
          </>
        </Box>
      </Container>
    </>
  );
};

export default Login;
