import React, { useState } from "react";
import axios from "axios";

const GeocodeFetcher = () => {
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [address, setAddress] = useState("");
  const [error, setError] = useState("");

  const fetchGeocode = async () => {
    try {
      const response = await axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
        params: {
          latlng: `${latitude},${longitude}`,
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        },
      });
      const addressComponents = response.data.results[0].address_components;
      const formattedAddress = `${addressComponents[1]?.short_name ? '"' + addressComponents[1].short_name + ", " : ""}${
        addressComponents[2]?.short_name ? addressComponents[2].short_name + ", " : ""
      }${addressComponents[3]?.short_name ? addressComponents[3].short_name + ", " : ""}${
        addressComponents[4]?.short_name ? addressComponents[4].short_name + ", " : ""
      }${addressComponents[5]?.short_name ? addressComponents[5].short_name + ", " : ""}${
        addressComponents[6]?.short_name ? addressComponents[6].short_name + '"' : ""
      }`;

      setAddress(formattedAddress);
      setError("");
    } catch (error) {
      setError("Error fetching geocode data");
      setAddress("");
    }
  };

  return (
    <div>
      <h1>Geocode Fetcher</h1>
      <div>
        <label>
          Latitude:
          <input type="text" value={latitude} onChange={(e) => setLatitude(e.target.value)} />
        </label>
      </div>
      <div>
        <label>
          Longitude:
          <input type="text" value={longitude} onChange={(e) => setLongitude(e.target.value)} />
        </label>
      </div>
      <button onClick={fetchGeocode}>Fetch Geocode</button>
      {error && <p>{error}</p>}
      {address && (
        <div>
          <h2>Address</h2>
          <p>{address}</p>
        </div>
      )}
    </div>
  );
};

export default GeocodeFetcher;
