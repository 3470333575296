import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, TextField, Button, Grid, Paper, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import Topbar from "../global/TopBar";
import { AuthContext } from "../../context/authContext";

const EditWorkEntries = () => {
  const navigate = useNavigate();
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [data, setData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const { currentUser } = useContext(AuthContext);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const fetchManagementData = async (employeeId) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `management/${employeeId}`, config);
      console.log(response.data);

      if (!response.data || !response.data.data) {
        console.log("No data found");
        setData([]);
        return;
      }

      setData(response.data.data);
    } catch (error) {
      console.log(error);
      // Handle error
    }
  };

  const getEmployees = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `users/`, config);

      if (response.data.data.length === 0) {
        console.log("não há users");
        return;
      } else {
        console.log(response.data.data);
        setEmployees(response.data.data);
      }
    } catch (error) {
      console.log(error);
      // logout();
    }
  };

  const handleEmployeeChange = (event) => {
    const employeeId = event.target.value;
    setSelectedEmployee(employeeId);
  };

  const handleChange = (index, field, value) => {
    const updatedData = [...data];
    // Parse the value to ensure it's a number
    const parsedValue = parseFloat(value);
    updatedData[index] = { ...updatedData[index], [field]: parsedValue };
    setData(updatedData);
  };

  const handleTimeChange = (index, time) => {
    const formattedTime = dayjs(time).format("HH:mm");
    const updatedData = [...data];
    updatedData[index] = { ...updatedData[index], entrance_time: formattedTime };
    setData(updatedData);
  };

  const handleSave = async () => {
    try {
      const response = await axios.patch(process.env.REACT_APP_API_CALL + `management`, data, config);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
    console.log("Saved data for employee", selectedEmployee, ":", data);
  };

  useEffect(() => {
    if (!currentUser || currentUser.email === null) {
      navigate("/login");
    } else {
      getEmployees();
      if (selectedEmployee) {
        fetchManagementData(selectedEmployee);
      }
    }
  }, [selectedEmployee]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Topbar />
      <Container>
        <Typography variant="h4" gutterBottom>
          Editar Horário de Trabalho
        </Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel id="select-employee-label">Selecionar Trabalhador</InputLabel>
          <Select labelId="select-employee-label" value={selectedEmployee} onChange={handleEmployeeChange}>
            {employees.map((employee) => (
              <MenuItem key={employee.id} value={employee.id}>
                {employee.email}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {data
          .filter((entry) => entry.employee_id === selectedEmployee)
          .map((entry, index) => (
            <Paper key={entry.id} style={{ padding: 16, marginBottom: 16 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">{entry.work_type}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TimePicker
                    label="Hora Limite Entrada"
                    value={dayjs(`2023-01-01T${entry.entrance_time}`)}
                    onChange={(newValue) => handleTimeChange(index, newValue)}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Mínimo Nº Horas"
                    type="number"
                    value={entry.minimum_hours}
                    onChange={(e) => handleChange(index, "minimum_hours", e.target.value)}
                    inputProps={{ min: 0 }}
                  />
                </Grid>
              </Grid>
            </Paper>
          ))}
        <Button variant="contained" color="primary" onClick={handleSave}>
          Guardar Alterações
        </Button>
      </Container>
    </LocalizationProvider>
  );
};

export default EditWorkEntries;
