import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import Topbar from "../global/TopBar";
import { LocalizationProvider, PickersDay } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import {
  MenuItem,
  Grid,
  Typography,
  Select,
  Backdrop,
  CircularProgress,
  Slide,
  Alert,
  AlertTitle,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Container,
  Box,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import "dayjs/locale/pt";
import { setDefaultOptions } from "date-fns";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import LensIcon from "@mui/icons-material/Lens";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CalendarAdmin = () => {
  const navigate = useNavigate();
  const [calendarData, setCalendarData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [type, setType] = useState("");
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [bankText, setBankText] = useState("");
  const [loading, setLoading] = useState(true);
  const [dialog, setDialog] = useState({});
  const [alert, setAlert] = useState(null);
  const [time, setTime] = useState(null);
  const [tokens, setTokens] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [userID, setUserID] = useState(null);

  const { currentUser } = useContext(AuthContext);
  const lastDayOfDecember2024 = dayjs("2024-12").endOf("month");
  const timerId = useRef(null);

  setDefaultOptions({ weekStartsOn: 1 });
  dayjs.extend(isSameOrAfter);
  dayjs.extend(weekday);
  dayjs.extend(weekOfYear);

  let isConfirmed = false;

  useEffect(() => {
    if (currentUser && currentUser.email !== "eduardo.pereira@heaboo.com" && currentUser.email !== "rui.teixeira@heaboo.com") {
      navigate("/");
    } else {
      setLoading(false);
      fetchUsers();
    }
  }, [currentUser]);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  const getNextBusinessDay = (startDate, daysToAdd) => {
    let daysAdded = 0;
    let currentDate = startDate;

    while (daysAdded < daysToAdd) {
      currentDate = currentDate.add(1, "day");
      // Skip weekends
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        daysAdded++;
      }
    }

    return currentDate;
  };

  const DateTwoBusinessDaysFromToday = () => {
    const today = dayjs();
    const twoBusinessDaysFromToday = getNextBusinessDay(today, 3).startOf("day");

    // if (
    //   type === "off_day_afternoon" ||
    //   type === "off_day_morning" ||
    //   type === "off_day" ||
    //   type.includes("custom_morning") ||
    //   type.includes("custom_afternoon")
    // ) {
    //   setMinDate(twoBusinessDaysFromToday);
    //   // setMinDate(dayjs().add(2, "day").startOf("day"));
    // } else {
    //   setMinDate(dayjs("2024-01").startOf("month"));
    // }

    setMinDate(dayjs("2024-01").startOf("month"));
  };

  const checkEventsForWeek = (clickedDate) => {
    // Calculate the start and end of the week (Sunday to Saturday)
    const weekStartDate = clickedDate.weekday(1); // Start of the week (Monday)
    const weekEndDate = clickedDate.weekday(7).endOf("day"); // End of the week (Sunday)

    // Check for events in the given week
    const hasSpecialEvent = calendarData.some((event) => {
      const eventDate = dayjs.unix(parseInt(event.date, 10));
      return (
        eventDate.isBetween(weekStartDate, weekEndDate, null, "[]") &&
        (event.type.includes("custom") ||
          event.type === "off_day" ||
          event.type === "off_day_afternoon" ||
          event.type === "off_day_morning")
      );
    });

    setTokens(hasSpecialEvent ? 2 : 0);
  };

  const fetchCalendarData = async (id) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `calendar/id/${id}`, config);
      setCalendarData(response.data.data);
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `users`, config);
      setUsersData(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    }
  };

  const checkInventory = async (id) => {
    setError(null);
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `inventory/${id}`, config);
      setInventoryData(response.data.data[0]);
    } catch (error) {
      setError(error);
    }
  };

  const updateCalendarData = async (date, type) => {
    if (inventoryData.tokens && inventoryData.tokens > 0) {
      try {
        checkEventsForWeek(date);
        if (inventoryData.bank < time) {
          if (type.includes("custom")) {
            clearTimeout(timerId.current);
            setAlert({
              severity: "error",
              title: "Aviso!",
              message: `Precisa de pelo menos ${time / 60} horas de banco para proceder com o pedido.`,
            });
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: adds a smooth scrolling effect
            });
            timerId.current = setTimeout(() => {
              setAlert(null);
            }, 5000);
          } else if (type === "off_day_afternoon") {
            clearTimeout(timerId.current);
            setAlert({ severity: "error", title: "Aviso!", message: "Precisa de pelo menos 4 horas de banco para tirar uma tarde." });
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: adds a smooth scrolling effect
            });
            timerId.current = setTimeout(() => {
              setAlert(null);
            }, 5000);
          } else if (type === "off_day_morning") {
            clearTimeout(timerId.current);
            setAlert({ severity: "error", title: "Aviso!", message: "Precisa de pelo menos 4 horas de banco para tirar uma manhã." });
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: adds a smooth scrolling effect
            });
            timerId.current = setTimeout(() => {
              setAlert(null);
            }, 5000);
          } else if (type === "off_day") {
            clearTimeout(timerId.current);
            setAlert({ severity: "error", title: "Aviso!", message: "Precisa de pelo menos 8 horas de banco para tirar um dia inteiro." });
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: adds a smooth scrolling effect
            });
            timerId.current = setTimeout(() => {
              setAlert(null);
            }, 5000);
          }
        } else if (type.includes("custom") && !isConfirmed) {
          setDialog({
            title: "PEDIDO HORAS MANHÃ / TARDE",
            subtitle: "Deseja proceder com o seu pedido?",
            description: "",
          });
          handleClickOpen();
        } else if ((type === "off_day_afternoon" || type === "off_day_morning") && !isConfirmed) {
          setDialog({
            title: "PEDIDO MANHÃ / TARDE",
            subtitle: "Deseja proceder com o seu pedido?",
            description: "",
          });
          handleClickOpen();
        } else if (type === "off_day" && !isConfirmed) {
          setDialog({
            title: "PEDIDO DIA COMPLETO",
            subtitle: "Deseja proceder com o seu pedido?",
            description: "",
          });
          handleClickOpen();
        } else if (
          (type === "off_day_afternoon" || type === "off_day_morning" || type === "off_day" || type.includes("custom")) &&
          isConfirmed
        ) {
          const selectedDayData = calendarData.find((item) => item.date === (new Date(date).getTime() / 1000).toString());

          if (selectedDayData.type === "weekend") {
            clearTimeout(timerId.current);
            setAlert({
              severity: "warning",
              title: "Erro!",
              message: "Este dia já está registado como dia de fim-de-semana. Não pode pedir horas para este dia!",
            });
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: adds a smooth scrolling effect
            });
            timerId.current = setTimeout(() => {
              setAlert(null);
            }, 5000);
            setOpen(false);
            isConfirmed = false;
            return;
          } else if (selectedDayData.type.includes("custom")) {
            clearTimeout(timerId.current);
            setAlert({
              severity: "warning",
              title: "Erro!",
              message: "Este dia já está registado com pedido de horas para manhã ou tarde. Não pode pedir horas novamente para este dia!",
            });
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: adds a smooth scrolling effect
            });
            timerId.current = setTimeout(() => {
              setAlert(null);
            }, 5000);
            setOpen(false);
            isConfirmed = false;
            return;
          } else if (selectedDayData.type === "off_day") {
            clearTimeout(timerId.current);
            setAlert({
              severity: "warning",
              title: "Erro!",
              message: "Este dia já está registado como dia de descanso total. Não pode pedir horas para este dia!",
            });
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: adds a smooth scrolling effect
            });
            timerId.current = setTimeout(() => {
              setAlert(null);
            }, 5000);
            setOpen(false);
            isConfirmed = false;
            return;
          } else if (selectedDayData.type === "off_day_morning") {
            clearTimeout(timerId.current);
            setAlert({
              severity: "warning",
              title: "Erro!",
              message: "Este dia já está registado como dia de descanso parcial (manhã). Não pode pedir horas para este dia!",
            });
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: adds a smooth scrolling effect
            });
            timerId.current = setTimeout(() => {
              setAlert(null);
            }, 5000);
            setOpen(false);
            isConfirmed = false;
            return;
          } else if (selectedDayData.type === "off_day_afternoon") {
            clearTimeout(timerId.current);
            setAlert({
              severity: "warning",
              title: "Erro!",
              message: "Este dia já está registado como dia de descanso parcial (tarde). Não pode pedir horas para este dia!",
            });
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: adds a smooth scrolling effect
            });
            timerId.current = setTimeout(() => {
              setAlert(null);
            }, 5000);
            setOpen(false);
            isConfirmed = false;
            return;
          } else if (selectedDayData.type === "holiday") {
            clearTimeout(timerId.current);
            setAlert({
              severity: "warning",
              title: "Erro!",
              message: "Este dia já está registado como feriado. Não pode pedir horas para este dia!",
            });
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: adds a smooth scrolling effect
            });
            timerId.current = setTimeout(() => {
              setAlert(null);
            }, 5000);
            setOpen(false);
            isConfirmed = false;
            return;
          } else if (selectedDayData.type === "vacation") {
            clearTimeout(timerId.current);
            setAlert({
              severity: "warning",
              title: "Erro!",
              message: "Este dia já está registado como dia de férias. Não pode pedir horas para este dia!",
            });
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: adds a smooth scrolling effect
            });
            timerId.current = setTimeout(() => {
              setAlert(null);
            }, 5000);
            setOpen(false);
            isConfirmed = false;
            return;
          } else {
            let tipo;
            if (type === "custom_morning") {
              if (time === "60") {
                tipo = "custom_morning_1";
              } else if (time === "120") {
                tipo = "custom_morning_2";
              } else if (time === "180") {
                tipo = "custom_morning_3";
              }
            } else if (type === "custom_afternoon") {
              if (time === "60") {
                tipo = "custom_afternoon_1";
              } else if (time === "120") {
                tipo = "custom_afternoon_2";
              } else if (time === "180") {
                tipo = "custom_afternoon_3";
              }
            } else {
              tipo = type;
            }

            const response = await axios.patch(
              process.env.REACT_APP_API_CALL + "calendar/",
              {
                id: selectedDayData.id,
                employee_id: userID,
                date: selectedDayData.date,
                type: tipo,
              },
              config
            );

            if (response.data.success === 1) {
              const response = await axios.patch(
                process.env.REACT_APP_API_CALL + "inventory/",
                {
                  id: inventoryData.id,
                  employee_id: userID,
                  bank: inventoryData.bank - time,
                  tokens: inventoryData.tokens - tokens,
                },
                config
              );

              if (response.data.success === 1) {
                setOpen(false);
                isConfirmed = false;
                checkInventory(userID);
              } else {
                console.log("error");
              }
            } else {
              console.log("error");
              return;
            }

            fetchCalendarData(userID);
          }
        } else {
          const selectedDayData = calendarData.find((item) => item.date === (new Date(date).getTime() / 1000).toString());
          const response = await axios.patch(
            process.env.REACT_APP_API_CALL + "calendar/",
            {
              id: selectedDayData.id,
              employee_id: userID,
              date: selectedDayData.date,
              type: type,
            },
            config
          );

          fetchCalendarData(userID);
        }
      } catch (error) {
        console.error("Error updating calendar data:", error);
      }
    } else {
      clearTimeout(timerId.current);
      setAlert({ severity: "error", title: "Aviso!", message: "Não tem tokens para proceder a tirar dias e/ou manhãs/tardes." });
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: adds a smooth scrolling effect
      });
      timerId.current = setTimeout(() => {
        setAlert(null);
      }, 5000);
    }
  };

  const CustomDay = (props) => {
    const { day, calendarData } = props;

    const isVacationDay =
      calendarData && calendarData.find((item) => item.date === (new Date(day).getTime() / 1000).toString() && item.type === "vacation");

    const isWorkDay =
      calendarData && calendarData.find((item) => item.date === (new Date(day).getTime() / 1000).toString() && item.type === "work");

    const isHoliday =
      calendarData && calendarData.find((item) => item.date === (new Date(day).getTime() / 1000).toString() && item.type === "holiday");

    const isOffDay =
      calendarData && calendarData.find((item) => item.date === (new Date(day).getTime() / 1000).toString() && item.type === "off_day");

    const isMorningOffDay =
      calendarData &&
      calendarData.find((item) => item.date === (new Date(day).getTime() / 1000).toString() && item.type === "off_day_morning");

    const isAfternoonOffDay =
      calendarData &&
      calendarData.find((item) => item.date === (new Date(day).getTime() / 1000).toString() && item.type === "off_day_afternoon");

    const isWeekendDay =
      calendarData && calendarData.find((item) => item.date === (new Date(day).getTime() / 1000).toString() && item.type === "weekend");

    const isCustomMorning =
      calendarData &&
      calendarData.find((item) => item.date === (new Date(day).getTime() / 1000).toString() && item.type.includes("custom_morning"));

    const isCustomAfternoon =
      calendarData &&
      calendarData.find((item) => item.date === (new Date(day).getTime() / 1000).toString() && item.type.includes("custom_afternoon"));

    let backgroundColor = "";
    let color = "";

    if (isVacationDay) {
      backgroundColor = "red";
      color = "white";
    } else if (isWorkDay) {
      backgroundColor = "green";
      color = "white";
    } else if (isHoliday) {
      backgroundColor = "blue";
      color = "white";
    } else if (isOffDay) {
      backgroundColor = "brown";
      color = "white";
    } else if (isMorningOffDay) {
      backgroundColor = "orange";
      color = "white";
    } else if (isAfternoonOffDay) {
      backgroundColor = "yellow";
      color = "black";
    } else if (isWeekendDay) {
      backgroundColor = "purple";
      color = "white";
    } else if (isCustomMorning) {
      backgroundColor = "pink";
      color = "white";
    } else if (isCustomAfternoon) {
      backgroundColor = "lightblue";
      color = "black";
    } else {
      backgroundColor = "black";
      color = "white";
    }

    return (
      <>
        <PickersDay
          {...props}
          // disabled={isVacationDay}
          sx={{
            backgroundColor: backgroundColor,
            color: color,
            "&.MuiPickersDay-root.Mui-selected": {
              backgroundColor: backgroundColor,
              color: color,
            },
            "&[aria-disabled='true']": {
              backgroundColor: "lightgray", // Example color for disabled days
            },
          }}
        />
      </>
    );
  };

  const calculateInventory = () => {
    // Check if excess minutes are negative
    if (inventoryData.bank < 0) {
      // Calculate absolute value of excess minutes
      const absoluteExcessMinutes = Math.abs(inventoryData.bank);

      // Round absolute excess minutes up to nearest multiple of 15
      const roundedExcessMinutes = Math.ceil(absoluteExcessMinutes / 15) * 15;

      const bankTextHours = Math.floor(roundedExcessMinutes / 60);
      const bankTextMinutes = roundedExcessMinutes % 60;

      // Construct bank text for negative excess minutes
      if (bankTextHours === 1 && bankTextMinutes > 0) {
        setBankText(`${bankTextHours}h e ${bankTextMinutes}m`);
      } else if (bankTextHours === 1 && bankTextMinutes === 0) {
        setBankText(`${bankTextHours} hora`);
      } else if (bankTextHours === 0 && bankTextMinutes > 0) {
        setBankText(`${bankTextMinutes} minutos`);
      } else if (bankTextHours > 0 && bankTextMinutes === 0) {
        setBankText(`${bankTextHours} horas`);
      } else if (bankTextHours > 0 && bankTextMinutes > 0) {
        setBankText(`${bankTextHours}h e ${bankTextMinutes}m`);
      } else if (bankTextHours === 0 && bankTextMinutes === 0) {
        setBankText("0");
      } else {
        setBankText("Não tem banco de horas.");
      }
    } else {
      // Calculate excess minutes in intervals of 15
      const excessMinutesInIntervals = Math.floor(inventoryData.bank / 15) * 15;
      const bankTextHours = Math.floor(excessMinutesInIntervals / 60);
      const bankTextMinutes = excessMinutesInIntervals % 60;

      // Construct bank text for positive excess minutes
      if (bankTextHours === 1 && bankTextMinutes > 0) {
        setBankText(`${bankTextHours}h e ${bankTextMinutes}m`);
      } else if (bankTextHours === 1 && bankTextMinutes === 0) {
        setBankText(`${bankTextHours} hora`);
      } else if (bankTextHours === 0 && bankTextMinutes > 0) {
        setBankText(`${bankTextMinutes} minutos`);
      } else if (bankTextHours > 0 && bankTextMinutes > 0) {
        setBankText(`${bankTextHours}h e ${bankTextMinutes}m`);
      } else if (bankTextHours > 0 && bankTextMinutes === 0) {
        setBankText(`${bankTextHours} horas`);
      } else if (bankTextHours === 0 && bankTextMinutes === 0) {
        setBankText("0");
      } else {
        setBankText("Não tem banco de horas.");
      }
    }
  };

  // const handleOpen = () => {
  //   setOpen(true);
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 3000);
  // };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    updateCalendarData(date, type);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
    if (event.target.value === "off_day_afternoon" || event.target.value === "off_day_morning") {
      setTime(240);
    } else if (event.target.value === "off_day") {
      setTime(480);
    } else if (
      event.target.value === "weekend" ||
      event.target.value === "holiday" ||
      event.target.value === "vacation" ||
      event.target.value === "work"
    ) {
      setTime(0);
    } else {
      setTime(null);
    }
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const handleUserChange = (event) => {
    setUserID(event.target.value);
    setLoading(true);
    fetchCalendarData(event.target.value);
    checkInventory(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = (time) => {
    isConfirmed = true;
    updateCalendarData(selectedDate, type, time);
  };

  useEffect(() => {
    // setLoading(true)

    if (
      inventoryData && // Check if inventoryData is not null or undefined
      Object.keys(inventoryData).length > 0 // Check if inventoryData has properties
    ) {
      calculateInventory();
    }

    if (bankText !== "") {
      setLoading(false);
    }
  }, [inventoryData, bankText]);

  useEffect(() => {
    DateTwoBusinessDaysFromToday();
  }, [type]);

  if (loading) {
    return (
      <>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }

  return (
    <>
      <Topbar />
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <Dialog open={open} TransitionComponent={Transition} keepMounted aria-describedby="alert-dialog-slide-description">
          <DialogTitle>{dialog.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">{dialog.subtitle}</DialogContentText>
            HORAS A RETIRAR: {time / 60 + " horas"}
            <br></br>
            HORAS APÓS TROCA: {(inventoryData.bank - time) / 60 + " horas"}
            <br></br>
            TOKENS A RETIRAR: {tokens}
            <br></br>
            TOKENS APÓS TROCA: {inventoryData.tokens - tokens}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
            >
              Voltar
            </Button>
            <Button
              onClick={() => {
                handleConfirm();
              }}
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </Backdrop>
      {alert !== null && (
        <Alert variant="filled" severity={alert.severity}>
          <AlertTitle>{alert.title}</AlertTitle>
          {alert.message}
        </Alert>
      )}

      <Container component="main" maxWidth="xs">
        <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
              <br></br>
              <Avatar sx={{ margin: "auto", bgcolor: "red" }}>
                <CalendarMonthOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h2" fontWeight={"bold"}>
                Calendário Administrador
              </Typography>
              <br></br>
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                rowSpacing={1}
                sx={{ mb: 4, fontWeight: "bold", textAlign: "center", bgcolor: "background.paper", borderRadius: 2 }}
              >
                <Grid item xs={12}>
                  <Typography
                    component="h1"
                    variant="h4"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 2,
                      marginLeft: 2,
                      marginRight: 2,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Escolha o Colaborador
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: 1 }}>
                  <Select value={userID} onChange={handleUserChange} displayEmpty inputProps={{ "aria-label": "Tipo de Pedido" }}>
                    <MenuItem value="" disabled>
                      Utilizador
                    </MenuItem>
                    {usersData.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.first_name} {user.last_name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>

            {userID !== null && (
              <>
                <Grid item xs={6}>
                  <Grid
                    container
                    rowSpacing={1}
                    sx={{ mb: 4, fontWeight: "bold", textAlign: "center", bgcolor: "background.paper", borderRadius: 2 }}
                  >
                    <Grid item xs={3}>
                      <Avatar
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: 1,
                          bgcolor: "brown",
                          width: 45,
                          height: 45,
                        }}
                      >
                        <AccountBalanceWalletOutlinedIcon />
                      </Avatar>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography component="h1" variant="h4" sx={{ fontWeight: "bold", textAlign: "center" }}>
                        Banco Horas
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {inventoryData.bank < 0 ? (
                        <>
                          <Typography component="h1" variant="h4" sx={{ mb: 1, fontWeight: "bold", textAlign: "center", color: "red" }}>
                            -{bankText}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography component="h1" variant="h4" sx={{ mb: 1, fontWeight: "bold", textAlign: "center", color: "green" }}>
                            {bankText}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Grid
                    container
                    rowSpacing={1}
                    sx={{ mb: 4, fontWeight: "bold", textAlign: "center", bgcolor: "background.paper", borderRadius: 2 }}
                  >
                    <Grid item xs={3}>
                      <Avatar
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: 1,
                          bgcolor: "brown",
                          width: 45,
                          height: 45,
                        }}
                      >
                        <TokenOutlinedIcon />
                      </Avatar>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        component="h1"
                        variant="h4"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: 2,
                          fontWeight: "bold",
                          textAlign: "center",
                          borderRadius: 2,
                        }}
                      >
                        Tokens
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {inventoryData.tokens <= 0 ? (
                        <>
                          <Typography component="h1" variant="h4" sx={{ mb: 1, fontWeight: "bold", textAlign: "center", color: "red" }}>
                            {inventoryData.tokens}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography component="h1" variant="h4" sx={{ mb: 1, fontWeight: "bold", textAlign: "center", color: "green" }}>
                            {inventoryData.tokens}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    rowSpacing={1}
                    sx={{ mb: 4, fontWeight: "bold", textAlign: "center", bgcolor: "background.paper", borderRadius: 2 }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        component="h1"
                        variant="h4"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: 2,
                          marginLeft: 2,
                          marginRight: 2,
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Escolha o tipo de pedido:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                      <Select value={type} onChange={handleTypeChange} displayEmpty inputProps={{ "aria-label": "Tipo de Pedido" }}>
                        <MenuItem value="" disabled>
                          Tipo de Pedido
                        </MenuItem>
                        <MenuItem value="weekend">Fim-de-semana</MenuItem>
                        <MenuItem value="holiday">Feriado</MenuItem>
                        <MenuItem value="vacation">Férias</MenuItem>
                        <MenuItem value="work">Trabalho</MenuItem>
                        <MenuItem value="off_day">Dia Completo</MenuItem>
                        <MenuItem value="off_day_morning">Manhã Completa</MenuItem>
                        <MenuItem value="off_day_afternoon">Tarde Completa</MenuItem>
                        <MenuItem value="custom_morning">Pedido Horas (Manhã)</MenuItem>
                        <MenuItem value="custom_afternoon">Pedido Horas (Tarde)</MenuItem>
                      </Select>
                    </Grid>

                    {(type.includes("custom_morning") || type.includes("custom_afternoon")) && (
                      <>
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: 2,
                              marginLeft: 2,
                              marginRight: 2,
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Escolha o número de horas a tirar:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                          <Select value={time} onChange={handleTimeChange} displayEmpty inputProps={{ "aria-label": "Número de Horas" }}>
                            <MenuItem value="" disabled>
                              Número de Horas
                            </MenuItem>
                            <MenuItem value="60">1 Hora</MenuItem>
                            <MenuItem value="120">2 Horas</MenuItem>
                            <MenuItem value="180">3 Horas</MenuItem>
                          </Select>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    ml: 1,
                    mb: 4,
                    fontWeight: "bold",
                    textAlign: "center",
                    bgcolor: "background.paper",
                    borderRadius: 2,
                    width: "100%",
                  }}
                >
                  <Typography variant="h1" sx={{ mb: 1, fontWeight: "bold", textAlign: "center", fontSize: "20px" }}>
                    Selecione os dias no calendário:
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt">
                    <DateCalendar
                      key={minDate}
                      sx={{ width: "100%", ml: -1, mr: 1, mb: -1 }}
                      views={["day"]}
                      openTo="month"
                      value={selectedDate}
                      onChange={handleDateChange}
                      onAccept={() => fetchCalendarData(userID)}
                      dayOfWeekFormatter={(weekday) => `${weekday.format("dd")}`}
                      minDate={minDate}
                      maxDate={lastDayOfDecember2024}
                      slots={{
                        day: CustomDay,
                      }}
                      slotProps={{
                        day: {
                          calendarData,
                        },
                        calendarHeader: { format: "MM/YYYY" },
                      }}
                      disabled={type === "" || type === null || time === "" || time === null}
                    />
                  </LocalizationProvider>
                </Grid>

                {/* Legenda */}
                <Grid item xs={12}>
                  <Grid
                    container
                    rowSpacing={1}
                    sx={{ mb: 4, fontWeight: "bold", textAlign: "center", bgcolor: "background.paper", borderRadius: 2 }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        component="h1"
                        variant="h4"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: 2,
                          marginRight: 2,
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Legenda Calendário
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <List sx={{ width: "100%", bgcolor: "background.paper", borderRadius: 2 }}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar sx={{ color: "green", width: "30px", height: "30px" }}>
                              <LensIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Dia de Trabalho" />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar sx={{ color: "purple", width: "30px", height: "30px" }}>
                              <LensIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Dia Fim-de-semana" />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar sx={{ color: "blue", width: "30px", height: "30px" }}>
                              <LensIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Feriado" />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar sx={{ color: "red", width: "30px", height: "30px" }}>
                              <LensIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Dia Férias" />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar sx={{ color: "brown", width: "30px", height: "30px" }}>
                              <LensIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Dia Completo OFF" />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar sx={{ color: "orange", width: "30px", height: "30px" }}>
                              <LensIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Manhã Completa OFF" />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar sx={{ color: "yellow", width: "30px", height: "30px" }}>
                              <LensIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Tarde Completa OFF" />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar sx={{ color: "pink", width: "30px", height: "30px" }}>
                              <LensIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Horas Manhã OFF" />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar sx={{ color: "lightblue", width: "30px", height: "30px" }}>
                              <LensIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Horas Tarde OFF" />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Container>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}></div>
    </>
  );
};

export default CalendarAdmin;
