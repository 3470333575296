import { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Typography,
  Container,
  useTheme,
  Grid,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  Avatar,
  Button,
  Divider,
  Chip,
  Alert,
  AlertTitle,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { AuthContext } from "../../context/authContext";
import Topbar from "../global/TopBar";
import ElectricBoltOutlinedIcon from "@mui/icons-material/ElectricBoltOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LensIcon from "@mui/icons-material/Lens";

const Help = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [error, setError] = useState(null);
  const [bankText, setBankText] = useState("");
  const [alert, setAlert] = useState(null);
  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const timerId = useRef(null);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setValue2(0);
  };

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  const checkInventory = async () => {
    setError(null);
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `inventory/${currentUser.id}`, config);
      setInventoryData(response.data.data[0]);

      if (response.data.data[0].bank >= 960 && response.data.data[0].bank !== 1920) {
        clearTimeout(timerId.current);
        setAlert({
          severity: "info",
          title: "Aviso!",
          message: `Tem pelo menos 16 horas no seu banco de horas! É recomendado que usufrua das mesmas antes de chegar ao limite (32 horas).`,
        });
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Optional: adds a smooth scrolling effect
        });
        timerId.current = setTimeout(() => {
          setAlert(null);
        }, 8000);
      } else if (response.data.data[0].bank === 1920) {
        clearTimeout(timerId.current);
        setAlert({
          severity: "warning",
          title: "Aviso!",
          message: `Atingiu o número máximo de horas que pode armazenar no seu banco! Usufrua das mesmas brevemente para voltar a armazenar!`,
        });
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Optional: adds a smooth scrolling effect
        });
        timerId.current = setTimeout(() => {
          setAlert(null);
        }, 8000);
      }
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (currentUser && currentUser.email) {
      checkInventory();
    } else {
      navigate("/login");
    }
  }, [currentUser]);

  if (loading) {
    return (
      <>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }

  return (
    <>
      <Topbar />
      {alert !== null && (
        <Alert variant="filled" severity={alert.severity}>
          <AlertTitle>{alert.title}</AlertTitle>
          {alert.message}
        </Alert>
      )}
      <Container component="main" maxWidth="xs">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} textAlign="center">
              <br></br>
              <Avatar sx={{ margin: "auto", bgcolor: "secondary.main" }}>
                <HelpOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h2" fontWeight={"bold"}>
                Ajuda
              </Typography>
              <br></br>
            </Grid>

            {value === 0 && (
              <Grid item xs={12} textAlign="center">
                <Typography variant="h2" fontWeight={"bold"}>
                  Selecione um dos tópicos
                </Typography>
                <Typography variant="body2" fontSize={"16px"}>
                  (arraste o menu seguinte para os lados para mais opções)
                </Typography>
                <br></br>
              </Grid>
            )}

            <Grid item xs={12}>
              <Divider sx={{ bgcolor: "black", fontWeight: "bold" }} />
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                <Tab icon={<SettingsSuggestOutlinedIcon />} label="Geral" value={1} />
                <Tab icon={<AppRegistrationOutlinedIcon />} label="Registo Atividade" value={2} />
                <Tab icon={<DashboardOutlinedIcon />} label="Dashboard" value={3} />
                <Tab icon={<CalendarMonthOutlinedIcon />} label="Calendário" value={4} />
              </Tabs>
            </Grid>

            {value === 1 && (
              <Grid item xs={12}>
                <Divider sx={{ bgcolor: "black", fontWeight: "bold" }} />
                <Tabs
                  value={value2}
                  onChange={handleChange2}
                  variant="scrollable"
                  scrollButtons={false}
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                >
                  <Tab icon={<AccountBalanceWalletOutlinedIcon />} label="Banco Horas" value={1} />
                  <Tab icon={<TokenOutlinedIcon />} label="Tokens" value={2} />
                  <Tab icon={<EmojiEventsOutlinedIcon />} label="Prémio" value={3} />
                  <Tab icon={<BugReportOutlinedIcon />} label="Bugs e Erros" value={4} />
                </Tabs>
              </Grid>
            )}

            {value === 2 && (
              <Grid item xs={12}>
                <Divider sx={{ bgcolor: "black", fontWeight: "bold" }} />
                <Tabs
                  value={value2}
                  onChange={handleChange2}
                  variant="scrollable"
                  scrollButtons={false}
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                >
                  <Tab icon={<MeetingRoomOutlinedIcon />} label="Entrada" value={5} />
                  <Tab icon={<ExitToAppOutlinedIcon />} label="Saída" value={6} />
                  <Tab icon={<SummarizeOutlinedIcon />} label="Relatório Tarefas" value={7} />
                  <Tab icon={<ChecklistOutlinedIcon />} label="Exemplos" value={8} />
                </Tabs>
              </Grid>
            )}

            {value === 3 && (
              <Grid item xs={12}>
                <Divider sx={{ bgcolor: "black", fontWeight: "bold" }} />
                <Tabs
                  value={value2}
                  onChange={handleChange2}
                  variant="scrollable"
                  scrollButtons={false}
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                >
                  <Tab icon={<SignalCellularAltOutlinedIcon />} label="Os Meus Dados" value={9} />
                  <Tab icon={<SpeedOutlinedIcon />} label="Performance" value={10} />
                  <Tab icon={<BarChartOutlinedIcon />} label="Assiduidade" value={11} />
                  <Tab icon={<PieChartOutlineOutlinedIcon />} label="Média Tarefas" value={12} />
                </Tabs>
              </Grid>
            )}

            {value === 4 && (
              <Grid item xs={12}>
                <Divider sx={{ bgcolor: "black", fontWeight: "bold" }} />
                <Tabs
                  value={value2}
                  onChange={handleChange2}
                  variant="scrollable"
                  scrollButtons={false}
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                >
                  <Tab icon={<CalendarMonthOutlinedIcon />} label="Calendário" value={13} />
                  <Tab icon={<AccountTreeOutlinedIcon />} label="Procedimento" value={14} />
                  <Tab icon={<DescriptionOutlinedIcon />} label="Legenda" value={15} />
                </Tabs>
              </Grid>
            )}

            {value2 === 1 && (
              <>
                <Grid item xs={12}>
                  <br></br>
                  <Avatar sx={{ margin: "auto", bgcolor: "brown" }}>
                    <AccountBalanceWalletOutlinedIcon />
                  </Avatar>
                  <Typography variant="h6" fontWeight={"bold"} textAlign="center">
                    Banco Horas
                  </Typography>
                  <br></br>
                  <Typography variant="subtitle2" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    Número de horas que acumulou durante os seus dias de trabalho.
                  </Typography>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    Poderá acumular um máximo de 32 horas. As horas que lhe seriam atribuídas após este máximo ser atingido serão perdidas.
                    É recomendável usufruir das mesmas para evitar esta perda.
                  </Typography>
                  <br></br>
                  <Typography variant="h6" fontWeight={"bold"} textAlign="center">
                    Ganhar Horas
                  </Typography>
                  <br></br>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    A aquisição de minutos para o banco de horas é feita em intervalos de 15 minutos. Se trabalhar 14 minutos a mais, não
                    recebe nada no banco de horas. Se trabalhar 22 minutos a mais, receberá 15 minutos.{" "}
                    <Link
                      href="#"
                      onClick={() => {
                        setValue(2);
                        setValue2(8);
                      }}
                      underline="always"
                      sx={{ cursor: "pointer" }}
                    >
                      Ver exemplos!
                    </Link>
                  </Typography>
                  <br></br>
                  <Typography variant="h6" fontWeight={"bold"} textAlign="center">
                    Perder Horas
                  </Typography>

                  <br></br>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    A perda de minutos para o banco de horas é feita em intervalos de 15 minutos. Se trabalhar 5 minutos a menos, serão
                    descontados 15 minutos no seu banco de horas. Se trabalhar 22 minutos a menos, serão descontados 30 minutos.{" "}
                    <Link
                      href="#"
                      onClick={() => {
                        setValue(2);
                        setValue2(8);
                      }}
                      underline="always"
                      sx={{ cursor: "pointer" }}
                    >
                      Ver exemplos!
                    </Link>
                  </Typography>
                </Grid>
              </>
            )}

            {value2 === 2 && (
              <>
                <Grid item xs={12}>
                  <br></br>
                  <Avatar sx={{ margin: "auto", bgcolor: "brown" }}>
                    <TokenOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Tokens
                  </Typography>
                  <br></br>
                  <Typography variant="subtitle2" gutterBottom fontSize={"20px"}>
                    Sistema de "pontuação" utilizado pela aplicação.
                  </Typography>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    O número de Tokens passará a 10 sempre que um novo mês começar, sendo este número o máximo.
                  </Typography>

                  <br></br>
                  <Typography variant="h6" fontWeight={"bold"} textAlign="center">
                    Ganhar Tokens
                  </Typography>
                  <br></br>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    De momento, não há forma automática para receber tokens (apenas se o administrador os fornecer).
                  </Typography>
                  <br></br>
                  <Typography variant="h6" fontWeight={"bold"} textAlign="center">
                    Perder Tokens
                  </Typography>
                  <br></br>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    Se chegar atrasado para o trabalho (após os 15 minutos de tolerância), perderá um token.
                  </Typography>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    Se não terminar o dia com o mínimo número de horas necessárias trabalhadas, perderá um token.
                  </Typography>
                  {/* <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    O custo para tirar um dia inteiro é de dois tokens.
                  </Typography> */}
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    O custo para tirar um dia inteiro, uma manhã ou uma tarde é de um token.
                  </Typography>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    Se perder todos os tokens num mês, não terá direito ao prémio monetário.
                  </Typography>
                </Grid>
              </>
            )}

            {value2 === 3 && (
              <>
                <Grid item xs={12}>
                  <br></br>
                  <Avatar sx={{ margin: "auto", bgcolor: "brown" }}>
                    <EmojiEventsOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Prémio
                  </Typography>
                  <br></br>
                  <Typography variant="subtitle2" gutterBottom fontSize={"20px"}>
                    Prémio monetário atribuído mensalmente.
                  </Typography>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    O valor do prémio é de 20€. Será atribuído no final de cada mês apenas se terminar o mesmo com o número de tokens
                    superior a 0.
                  </Typography>
                </Grid>
              </>
            )}

            {value2 === 4 && (
              <>
                <Grid item xs={12}>
                  <br></br>
                  <Avatar sx={{ margin: "auto", bgcolor: "brown" }}>
                    <BugReportOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Bugs e Erros
                  </Typography>
                  <br></br>
                  <Typography variant="subtitle2" gutterBottom fontSize={"20px"}>
                    Erros de aplicação e/ou pequenos "bugs" que possam ocorrer durante a sua utilização.
                  </Typography>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    Nenhum tipo de aplicação é perfeita, podendo surgir assim erros no uso da aplicação de várias origens. De relembrar que
                    a culpa nunca é do utilizador, mas sim do sistema que foi implementado. Assim, sempre que surgirem bugs, erros, ou
                    outras questões relacionadas com a aplicação, por favor contactem um administrador e informem do problema com o maior
                    número de detalhes possível.
                  </Typography>
                </Grid>
              </>
            )}

            {value2 === 5 && (
              <>
                <Grid item xs={12}>
                  <br></br>
                  <Avatar sx={{ margin: "auto", bgcolor: "brown" }}>
                    <MeetingRoomOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Entrada
                  </Typography>
                  <br></br>
                  <Typography variant="subtitle2" gutterBottom fontSize={"20px"}>
                    Início do dia de trabalho.
                  </Typography>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    Ao registar a sua entrada, terá de ter em conta o seu tipo de trabalho ("Home", "Office" ou "Outdoors"), pois este é que
                    definirá a hora de começo base para cálculo de atrasos.
                  </Typography>
                  <br></br>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Atrasos
                  </Typography>
                  <br></br>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    No caso de chegar depois do tempo estabelecido (e respetivos 15 minutos de tolerância), terá de preencher a justificação
                    no campo de texto adequado. Só ao preencher a justificação e ao clicar no botão "Registar Entrada" é que poderá com o
                    seu dia de trabalho normalmente.
                  </Typography>
                </Grid>
              </>
            )}

            {value2 === 6 && (
              <>
                <Grid item xs={12}>
                  <br></br>
                  <Avatar sx={{ margin: "auto", bgcolor: "brown" }}>
                    <ExitToAppOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Saída
                  </Typography>
                  <br></br>
                  <Typography variant="subtitle2" gutterBottom fontSize={"20px"}>
                    Término do dia de trabalho.
                  </Typography>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    Ao registar a sua saída, terá de ter em conta o número de minutos que esteve a almoçar (30, 45 ou 60 minutos). Ao clicar
                    em "Sair", será feito o cálculo das horas que trabalhou efetivamente nesse dia, registando o mesmo se fizer o número de
                    horas estabelecido. Procederá para o preenchimento do relatório de tarefas, obrigatório para completar o registo do dia.
                  </Typography>
                  <br></br>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Dia Incompleto
                  </Typography>
                  <br></br>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    No caso de terminar o dia antes do tempo, não atingindo assim o número de horas estabelecido, terá de justificar o
                    porquê do mesmo. Só após escrever a sua justificação e clicar em "Registar Saída" é que o dia fica fechado, mas
                    incompleto, pois ficará a faltar o preenchimento obrigatório do relatório de tarefas.
                  </Typography>
                </Grid>
              </>
            )}

            {value2 === 7 && (
              <>
                <Grid item xs={12}>
                  <br></br>
                  <Avatar sx={{ margin: "auto", bgcolor: "brown" }}>
                    <SummarizeOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Relatório Tarefas
                  </Typography>
                  <br></br>
                  <Typography variant="subtitle2" gutterBottom fontSize={"20px"}>
                    Relatório detalhado das tarefas realizadas e do tempo alocado a cada uma.
                  </Typography>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    Depois de registar a sua saída com sucesso, terá de preencher o relatório de tarefas, finalizando assim o seu dia. Para
                    tal, terá de alocar todos os minutos trabalhados às tarefas previamente selecionadas. Para cada tarefa selecionada,
                    deverá também escrever notas em cada uma, detalhando o que fez de forma sucinta.
                  </Typography>
                </Grid>
              </>
            )}

            {value2 === 8 && (
              <>
                <Grid item xs={12}>
                  <br></br>
                  <Avatar sx={{ margin: "auto", bgcolor: "brown" }}>
                    <ChecklistOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Exemplos
                  </Typography>
                  <br></br>
                  <Typography variant="subtitle2" gutterBottom fontSize={"20px"}>
                    Exemplos de registo de atividade e respetivos resultados.
                  </Typography>
                  <br></br>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Exemplo 1
                  </Typography>
                  <br></br>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    O meu horário de trabalho é de 8 horas. A minha hora de entrada oficial é às 09:00, em modo "Office". Eu entrei às
                    08:55, almocei em 30 minutos, e saí às 17h53. Como cheguei a horas, não perco token. Como fiz 28 minutos a mais de
                    trabalho, armazeno 15 minutos no meu banco de horas e também não perco token.
                  </Typography>
                  <br></br>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Exemplo 2
                  </Typography>
                  <br></br>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    O meu horário de trabalho é de 8 horas. A minha hora de entrada oficial é às 09:00, em modo "Home". Eu entrei às 09:15,
                    almocei em 60 minutos, e saí às 18h10. Como cheguei a horas (dentro do prazo dos 15 minutos de tolerância), não perco
                    token. No entanto, como fiz 5 minutos a menos de trabalho (7h55min. trabalhados), perco 15 minutos no meu banco de horas
                    e também perco um token.
                  </Typography>
                </Grid>
              </>
            )}

            {value2 === 9 && (
              <>
                <Grid item xs={12}>
                  <br></br>
                  <Avatar sx={{ margin: "auto", bgcolor: "brown" }}>
                    <SignalCellularAltOutlinedIcon />
                  </Avatar>
                  <Typography variant="h6" fontWeight={"bold"} textAlign="center">
                    Os Meus Gráficos
                  </Typography>
                  <br></br>
                  <Typography variant="subtitle2" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    Parte dedicada à disposição de todos os gráficos referentes ao empregado, com dados mensais.
                  </Typography>
                  <Typography variant="h6" fontWeight={"bold"} textAlign="center">
                    Performance
                  </Typography>
                  <br></br>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    Gráfico dedicado à medição da sua performance mensal (neste momento, apenas baseado na sua assiduidade).{" "}
                    <Link
                      href="#"
                      onClick={() => {
                        setValue(3);
                        setValue2(10);
                      }}
                      underline="always"
                      sx={{ cursor: "pointer" }}
                    >
                      Explicação!
                    </Link>
                  </Typography>
                  <br></br>
                  <Typography variant="h6" fontWeight={"bold"} textAlign="center">
                    Assiduidade
                  </Typography>

                  <br></br>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    Gráfico dedicado à demonstração da sua assiduidade mensal, representando os minutos em atraso.{" "}
                    <Link
                      href="#"
                      onClick={() => {
                        setValue(3);
                        setValue2(11);
                      }}
                      underline="always"
                      sx={{ cursor: "pointer" }}
                    >
                      Explicação!
                    </Link>
                  </Typography>
                  <br></br>
                  <Typography variant="h6" fontWeight={"bold"} textAlign="center">
                    Média Tarefas
                  </Typography>

                  <br></br>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    Gráfico dedicado à demonstração do tempo médio trabalhado em cada tarefa.{" "}
                    <Link
                      href="#"
                      onClick={() => {
                        setValue(3);
                        setValue2(12);
                      }}
                      underline="always"
                      sx={{ cursor: "pointer" }}
                    >
                      Explicação!
                    </Link>
                  </Typography>
                </Grid>
              </>
            )}

            {value2 === 10 && (
              <>
                <Grid item xs={12}>
                  <br></br>
                  <Avatar sx={{ margin: "auto", bgcolor: "brown" }}>
                    <SpeedOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Performance
                  </Typography>
                  <br></br>
                  <Typography variant="subtitle2" gutterBottom fontSize={"20px"}>
                    Gráfico dedicado à medição da sua performance mensal (neste momento, apenas baseado na sua assiduidade).
                  </Typography>
                  <br></br>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    O gráfico varia entre os valores 0 e 100, sendo que 0 representa uma performance medíocre, e 100 uma performance
                    excelente. Os valores vão variando consoante os dias trabalhados no mês escolhido, assim como os intervalos de atraso
                    demonstrados no gráfico{" "}
                    <Link
                      href="#"
                      onClick={() => {
                        setValue(3);
                        setValue2(11);
                      }}
                      underline="always"
                      sx={{ cursor: "pointer" }}
                    >
                      assiduidade.
                    </Link>
                  </Typography>
                </Grid>
              </>
            )}

            {value2 === 11 && (
              <>
                <Grid item xs={12}>
                  <br></br>
                  <Avatar sx={{ margin: "auto", bgcolor: "brown" }}>
                    <BarChartOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Assiduidade
                  </Typography>
                  <br></br>
                  <Typography variant="subtitle2" fontSize={"20px"}>
                    Gráfico dedicado à demonstração da sua assiduidade mensal, representando os minutos em atraso.
                  </Typography>
                  <br></br>
                  <Typography variant="body1" fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    O gráfico de barras mostra, por cada dia do mês escolhido, o valor dos minutos em atraso.{" "}
                    <Typography component="span" fontSize={"20px"} sx={{ color: "green", textAlign: "justify" }}>
                      0 ou inferior
                    </Typography>{" "}
                    (valores negativos), assume a{" "}
                    <Typography component="span" fontSize={"20px"} sx={{ color: "green", textAlign: "justify" }}>
                      {" "}
                      cor verde
                    </Typography>
                    , pois chegou antes do tempo, afetando muito positivamente o gráfico de performance mensal.{" "}
                    <Typography component="span" fontSize={"20px"} sx={{ color: "gold", textAlign: "justify" }}>
                      Entre 1 e 15
                    </Typography>
                    , assume a{" "}
                    <Typography component="span" fontSize={"20px"} sx={{ color: "gold", textAlign: "justify" }}>
                      cor amarela
                    </Typography>
                    , chegou dentro do tempo de tolerância, tendo afetado ligeiramente o seu gráfico de performance mensal, de forma
                    negativa.
                    <Typography component="span" fontSize={"20px"} sx={{ color: "red", textAlign: "justify" }}>
                      {" "}
                      Acima de 15
                    </Typography>
                    , assume a{" "}
                    <Typography component="span" fontSize={"20px"} sx={{ color: "red", textAlign: "justify" }}>
                      cor vermelha
                    </Typography>
                    , afetando muito negativamente o gráfico de performance mensal, contando como atraso.
                  </Typography>
                </Grid>
              </>
            )}

            {value2 === 12 && (
              <>
                <Grid item xs={12}>
                  <br></br>
                  <Avatar sx={{ margin: "auto", bgcolor: "brown" }}>
                    <PieChartOutlineOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Média Tarefas
                  </Typography>
                  <br></br>
                  <Typography variant="subtitle2" gutterBottom fontSize={"20px"}>
                    Gráfico dedicado à demonstração do tempo médio trabalhado em cada tarefa.{" "}
                  </Typography>
                  <br></br>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    O tempo relativo a cada tarefa realizada mensalmente é apresentado em minutos, dependendo do tempo atribuído a cada uma
                    no próprio relatório das tarefas.
                  </Typography>
                </Grid>
              </>
            )}

            {value2 === 13 && (
              <>
                <Grid item xs={12}>
                  <br></br>
                  <Avatar sx={{ margin: "auto", bgcolor: "brown" }}>
                    <CalendarMonthOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Calendário
                  </Typography>
                  <br></br>
                  <Typography variant="subtitle2" gutterBottom fontSize={"20px"}>
                    Calendário do trabalhador.
                  </Typography>
                  <br></br>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    Com o calendário, poderá ver todos os dias que tem marcado com uma determinada tipologia{" "}
                    <Link
                      href="#"
                      onClick={() => {
                        setValue(4);
                        setValue2(15);
                      }}
                      underline="always"
                      sx={{ cursor: "pointer" }}
                    >
                      (ver legenda),
                    </Link>
                    <Typography component="span" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                      {" "}
                      assim como marcar dias de trabalho off, ou apenas manhãs/tardes off.
                    </Typography>
                  </Typography>
                </Grid>
              </>
            )}

            {value2 === 14 && (
              <>
                <Grid item xs={12}>
                  <br></br>
                  <Avatar sx={{ margin: "auto", bgcolor: "brown" }}>
                    <AccountTreeOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Procedimento
                  </Typography>
                  <br></br>
                  <Typography variant="subtitle2" gutterBottom fontSize={"20px"}>
                    Procedimento para a utilização do calendário.
                  </Typography>
                  <br></br>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    Antes de tirar dias, manhãs ou tardes, deverá primeiro falar com Rui Teixeira, para verificar se não existe nenhum
                    problema em fazê-lo. Depois de confirmar, verifique que tem tokens disponíveis, e que tem pelo menos 4 horas para uma
                    manhã/tarde, ou 8 horas para um dia completo, no seu banco de horas.
                  </Typography>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    Apenas poderá tirar dias, manhãs ou tardes 48 horas (dias úteis) depois do pedido. Se estiver a fazer o pedido numa segunda-feira, só
                    poderá escolher a partir de quarta-feira. Se estiver a fazer o pedido numa sexta-feira, só podera escolher a partir da quarta-feira seguinte.
                  </Typography>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify", fontWeight: "bold" }}>
                    DEVERÁ, APÓS TIRAR O DIA OU MANHÃ/TARDE, MARCAR TAMBÉM NO SEU CALENDÁRIO DE EMPRESA{" "}
                    <Link
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        window.open("https://outlook.office.com/calendar/view/month", "_blank");
                      }}
                      underline="always"
                      sx={{ cursor: "pointer" }}
                    >
                      (CLICAR AQUI!)
                    </Link>
                  </Typography>
                  <br></br>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Tirar Dia
                  </Typography>
                  <br></br>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    No separador Calendário, selecionar o tipo de dia "Dia Completo", e clicar no dia que pretende tirar. Janelas de
                    confirmação irão surgir no seu ecrã, confirme conforme necessário. Depois de tudo completo, verificará que a cor do dia,
                    segundo a{" "}
                    <Link
                      href="#"
                      onClick={() => {
                        setValue(4);
                        setValue2(15);
                      }}
                      underline="always"
                      sx={{ cursor: "pointer" }}
                    >
                      legenda
                    </Link>
                    <Typography component="span" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                      , ficará diferente consoante o tipo escolhido. Poderá verificar também que o seu número de banco de horas reduziu,
                      assim como o número de tokens.
                    </Typography>
                  </Typography>
                  <br></br>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Tirar Manhã/Tarde
                  </Typography>
                  <br></br>
                  <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                    No separador Calendário, selecionar o tipo de dia "Manhã" ou "Tarde", e clicar no dia que pretende tirar. Janelas de
                    confirmação irão surgir no seu ecrã, confirme conforme necessário. Depois de tudo completo, verificará que a cor do dia,
                    segundo a{" "}
                    <Link
                      href="#"
                      onClick={() => {
                        setValue(4);
                        setValue2(15);
                      }}
                      underline="always"
                      sx={{ cursor: "pointer" }}
                    >
                      legenda
                    </Link>
                    <Typography component="span" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                      , ficará diferente consoante o tipo escolhido. Poderá verificar também que o seu número de banco de horas reduziu,
                      assim como o número de tokens.
                    </Typography>
                  </Typography>
                </Grid>
              </>
            )}

            {value2 === 15 && (
              <>
                <Grid item xs={12}>
                  <br></br>
                  <Avatar sx={{ margin: "auto", bgcolor: "brown" }}>
                    <DescriptionOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h6" fontWeight={"bold"} textAlign="center">
                    Legenda
                  </Typography>
                  <br></br>
                  <Typography variant="subtitle2" gutterBottom fontSize={"20px"}>
                    Legenda de cores do tipo de dia, no calendário.
                  </Typography>
                  <br></br>
                  <Grid item xs={12}>
                    <List sx={{ width: "100%", bgcolor: "background.paper", borderRadius: 2 }}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ color: "green", width: "30px", height: "30px" }}>
                            <LensIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Dia de Trabalho" />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ color: "purple", width: "30px", height: "30px" }}>
                            <LensIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Dia Fim-de-semana" />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ color: "blue", width: "30px", height: "30px" }}>
                            <LensIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Feriado" />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ color: "red", width: "30px", height: "30px" }}>
                            <LensIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Dia Férias" />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ color: "brown", width: "30px", height: "30px" }}>
                            <LensIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Dia Completo OFF" />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ color: "orange", width: "30px", height: "30px" }}>
                            <LensIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Manhã OFF" />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ color: "yellow", width: "30px", height: "30px" }}>
                            <LensIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Tarde OFF" />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Help;
