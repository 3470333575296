import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, TextField, Button, Grid, Paper, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import axios from "axios";
import Topbar from "../global/TopBar";
import { AuthContext } from "../../context/authContext";

const Inventory = () => {
  const navigate = useNavigate();
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [data, setData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const { currentUser } = useContext(AuthContext);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const fetchInventoryData = async (employeeId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_CALL}inventory/${employeeId}`, config);
      if (response.data && response.data.data) {
        setData(response.data.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getEmployees = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_CALL}users/`, config);
      if (response.data && response.data.data) {
        setEmployees(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmployeeChange = (event) => {
    const employeeId = event.target.value;
    setSelectedEmployee(employeeId);
  };

  const handleChange = (index, field, value) => {
    const updatedData = [...data];
    updatedData[index] = { ...updatedData[index], [field]: value };
    setData(updatedData);
  };

  const handleSave = async () => {
    try {
      await axios.patch(`${process.env.REACT_APP_API_CALL}inventory`, data[0], config);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentUser && currentUser.email !== "eduardo.pereira@heaboo.com" && currentUser.email !== "rui.teixeira@heaboo.com") {
      navigate("/");
    } else {
      getEmployees();
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (selectedEmployee) {
      fetchInventoryData(selectedEmployee);
    }
  }, [selectedEmployee]);

  return (
    <>
      <Topbar />
      <Container>
        <Typography variant="h4" gutterBottom>
          Inventário
        </Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel id="select-employee-label">Selecionar Colaborador</InputLabel>
          <Select labelId="select-employee-label" value={selectedEmployee} onChange={handleEmployeeChange}>
            {employees.map((employee) => (
              <MenuItem key={employee.id} value={employee.id}>
                {employee.email}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {data
          .filter((entry) => entry.employee_id === selectedEmployee)
          .map((entry, index) => (
            <Paper key={entry.id} style={{ padding: 16, marginBottom: 16 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Banco"
                    value={entry.bank || ""}
                    onChange={(e) => handleChange(index, "bank", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Tokens"
                    type="number"
                    value={entry.tokens || ""}
                    onChange={(e) => handleChange(index, "tokens", e.target.value)}
                    inputProps={{ min: 0 }}
                  />
                </Grid>
              </Grid>
            </Paper>
          ))}
        <Button variant="contained" color="primary" onClick={handleSave}>
          Guardar Alterações
        </Button>
      </Container>
    </>
  );
};

export default Inventory;
