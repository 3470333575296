import { createContext, useState, useEffect } from "react";
import axios from "axios";

export const AuthContext = createContext();

const config = {
    headers: {
        "access-token": process.env.REACT_APP_ACCESS_TOKEN,
        "Content-Type": "application/json",
    },
    withCredentials: true,
};

export const AuthContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null)

    const login = async (values) => {

        console.log("cenas")

        const response = await axios.post(
            process.env.REACT_APP_API_CALL + "users/login",
            values,
            config
        )

        console.log(response.data)

        setCurrentUser(response.data.data)

    }

    const logout = async (values) => {
        const response = await axios.post(
            process.env.REACT_APP_API_CALL + "users/logout",
            values,
            config)
        setCurrentUser(null)
    }

    const verifyUser = async (values) => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_CALL + `users/${values.email}`,
                config);

            if ((response.data.data[0].email !== currentUser.email) || currentUser.email === undefined || currentUser.email === null) {
                logout();
                setCurrentUser(null);
            }
        } catch (error) {
            console.log(error)
            logout();
            setCurrentUser(null);
        }
    };

    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(currentUser));
    }, [currentUser])

    return (
        <AuthContext.Provider value={{ currentUser, login, logout, verifyUser }}>{children}</AuthContext.Provider>
    )
}