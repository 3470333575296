import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../context/authContext";
import { Box, Typography, Container, useTheme, Grid, Select, MenuItem } from "@mui/material";
import { PieChart, LineChart, BarChart, Gauge, gaugeClasses } from "@mui/x-charts";
import Topbar from "../global/TopBar";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [activityData, setActivityData] = useState([]);
  const [managementData, setManagementData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [delayData, setDelayData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser && currentUser.email) {
      checkActivity();
      checkManagementInfo();
    } else {
      navigate("/login");
    }
  }, [currentUser]);

  useEffect(() => {
    // Initialize selectedMonth to current month when component mounts
    const currentMonth = new Date().toISOString().slice(0, 7); // Format: "YYYY-MM"
    setSelectedMonth(currentMonth);
  }, []);

  const checkActivity = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `activity`, config);
      setActivityData(response.data.data);
      setLoading(false);
      console.log(response.data.data);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const checkManagementInfo = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `management`, config);
      setManagementData(response.data.data);
      setLoading(false);
      console.log(response.data.data);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const filterData = () => {
    let filtered = activityData;

    if (selectedEmployeeId) {
      filtered = filtered.filter((item) => item.employee_id === selectedEmployeeId);
    }

    if (selectedMonth) {
      const selectedMonthData = selectedMonth.split("-")[1];
      filtered = filtered.filter((item) => {
        const activityMonth = new Date(item.date.split("-").reverse().join("-")).getMonth() + 1;
        return activityMonth === parseInt(selectedMonthData);
      });
    }

    setFilteredData(filtered);
    calculateDelays(filtered);
  };

  const calculateDelays = (filtered) => {
    const delays = filtered.map((activity) => {
      const workType = activity.work_type;
      const managementInfo = managementData.find((m) => m.work_type === workType);

      if (managementInfo) {
        const entranceTimeParts = managementInfo.entrance_time.split(":");
        const entranceTimeMs = parseInt(entranceTimeParts[0]) * 60 * 60 * 1000 + parseInt(entranceTimeParts[1]) * 60 * 1000;

        // Convert start_hour to milliseconds (assuming it's a Unix timestamp)
        const startTimeMs = parseInt(activity.start_hour);

        const actualStartTimeDate = new Date(startTimeMs);
        const actualStartHourMinutes = actualStartTimeDate.getHours() * 60;
        const actualStartMinutes = actualStartHourMinutes + actualStartTimeDate.getMinutes();

        console.log("Actual Start Time:", actualStartTimeDate);
        console.log("Actual Start Time in MS:", startTimeMs);
        console.log("Actual Start Time in Minutes:", actualStartMinutes);

        const delayMs = actualStartMinutes - entranceTimeMs / (60 * 1000); // Convert entranceTimeMs to minutes
        const delayMinutes = delayMs; // Keep delay in minutes

        // Extract only the day from the date and convert it to a number
        const day = parseInt(activity.date.split("-")[0]);

        console.log("Activity Date:", activity.date);
        console.log("Entrance Time in MS:", entranceTimeMs);
        console.log("Actual Start Time in MS:", actualStartMinutes);
        console.log("Delay in MS:", delayMs);
        console.log("Delay in Minutes:", delayMinutes);

        return {
          day: day,
          delayMinutes: delayMinutes,
        };
      }

      return {
        day: activity.date.split("-")[0],
        delayMinutes: 0,
      };
    });

    const minDelay = Math.min(...delays.map((d) => d.delayMinutes));
    const maxDelay = Math.max(...delays.map((d) => d.delayMinutes));

    const minY = Math.min(minDelay, 0); // Include zero as the center
    const maxY = Math.max(maxDelay, 0);

    const yRange = [minY, maxY];

    const aggregatedDelays = delays.reduce((acc, curr) => {
      acc[curr.day] = (acc[curr.day] || 0) + curr.delayMinutes;
      return acc;
    }, {});

    const delayDataFormatted = Object.keys(aggregatedDelays).map((day) => ({
      day: parseInt(day), // Convert day back to number
      delayMinutes: aggregatedDelays[day],
    }));

    console.log("Delay Data:", delayDataFormatted);

    setDelayData(delayDataFormatted);
  };

  // Call filterData whenever selectedEmployeeId or selectedMonth changes
  useEffect(() => {
    filterData();
  }, [selectedEmployeeId, selectedMonth]);

  const theme = useTheme();

  const generateMonthOptions = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 12 }, (_, i) => {
      const month = (i + 1).toString().padStart(2, "0");
      return {
        value: `${currentYear}-${month}`,
        label: new Date(`${currentYear}-${month}-01`).toLocaleString("default", { month: "long" }),
      };
    });
  };

  // Sample data for PieChart
  const pieChartData = [
    { id: 0, value: 10, label: "series A" },
    { id: 1, value: 15, label: "series B" },
    { id: 2, value: 20, label: "series C" },
  ];

  const getBarColor = (value) => {
    return value >= 0 ? "red" : "green"; // Positive values are red, negative values are green
  };

  const employeeEmailLookup = {
    1: "eduardo.pereira@heaboo.com",
    // add other employee_id to email mappings here
  };

  return (
    <>
      <Topbar />
      <Container component="main" maxWidth="md">
        <Box display="flex" flexDirection="column" alignItems="center">
          {loading ? (
            <Typography variant="h6">Loading...</Typography>
          ) : error ? (
            <Typography variant="h6" color="error">
              Error: {error.message}
            </Typography>
          ) : (
            <>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <Typography component="h1" variant="h4" sx={{ mb: 4, fontWeight: "bold", textAlign: "center" }}>
                    RESUMO DADOS
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography component="h1" variant="h4" sx={{ mb: 4, fontWeight: "bold", textAlign: "center" }}>
                    Banco Horas: 2 Horas
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography component="h1" variant="h4" sx={{ mb: 4, fontWeight: "bold", textAlign: "center" }}>
                    Tokens: 6
                  </Typography>
                </Grid>
                {/* Dropdown for selecting employee ID */}
                <Grid item xs={6}>
                  <Select value={selectedEmployeeId} onChange={(e) => setSelectedEmployeeId(e.target.value)} sx={{ mr: 2 }}>
                    {Array.from(new Set(activityData.map((item) => item.employee_id))).map((id) => (
                      <MenuItem key={id} value={id}>
                        {employeeEmailLookup[id]}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                {/* Dropdown for selecting month */}
                <Grid item xs={12}>
                  <Select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                    {generateMonthOptions().map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>

              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={6} mt={5}>
                  <Typography component="h1" variant="h4" sx={{ mb: 4, fontWeight: "bold", textAlign: "center" }}>
                    Assiduidade
                  </Typography>
                  <BarChart
                    series={[{ data: delayData.map((d) => d.delayMinutes) }]}
                    height={290}
                    xAxis={[{ data: delayData.map((d) => d.day), scaleType: "band" }]}
                    yAxis={[
                      {
                        type: "number",
                        colorMap: {
                          type: "piecewise",
                          thresholds: [0],
                          colors: ["green", "red"],
                        },
                      },
                    ]} // Specify the domain for the y-axis
                    margin={{ top: 10, left: 30, right: 10 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <PieChart
                      margin={{ bottom: 30 }}
                      series={[
                        {
                          data: pieChartData,
                          innerRadius: 30,
                          outerRadius: 100,
                          paddingAngle: 5,
                          cornerRadius: 5,
                          startAngle: 0,
                          endAngle: 360,
                          cx: 150,
                          cy: 120, // Adjusted to move the chart upwards
                        },
                      ]}
                      height={350} // Increased height to provide more space for the legend
                      width={300}
                      slotProps={{
                        legend: {
                          direction: "row",
                          position: { vertical: "bottom", horizontal: "middle" },
                          padding: 20, // Adjust padding as needed
                        },
                      }}
                    />
                  </Box>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <LineChart
                      xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                      series={[
                        {
                          data: [2, 5.5, 2, 8.5, 1.5, 5],
                        },
                      ]}
                      width={350}
                      height={300}
                    />
                  </Box>
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <Typography component="h1" variant="h4" sx={{ mb: 4, fontWeight: "bold", textAlign: "center" }}>
                    Performance Mensal
                  </Typography>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Gauge
                      width={250}
                      height={100}
                      value={80}
                      valueMin={0}
                      valueMax={100}
                      startAngle={-90}
                      endAngle={90}
                      cornerRadius="75%"
                      innerRadius="70%"
                      outerRadius="100%"
                      sx={(theme) => ({
                        [`& .${gaugeClasses.valueText}`]: {
                          fontSize: 40,
                          transform: "translate(0px, -20px)",
                          fontWeight: "bold",
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                          fill: "#52b202",
                        },
                        [`& .${gaugeClasses.referenceArc}`]: {
                          fill: theme.palette.text.disabled,
                        },
                      })}
                    />
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

export default AdminDashboard;
