import React, { useState, useEffect, useContext } from "react";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";
import TimerIcon from "@mui/icons-material/Timer";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { AuthContext } from "../../context/authContext";
import Topbar from "../global/TopBar";
import axios from "axios";

export const Report = () => {
  const [workedMinutes, setWorkedMinutes] = useState(null)
  const [tasks, setTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [activity, setActivity] = useState([]);
  const [comments, setComments] = useState({});
  const [timeSpent, setTimeSpent] = useState({});
  const [openSliderForTask, setOpenSliderForTask] = useState(null);
  const [openCommentForTask, setOpenCommentForTask] = useState(null);
  const { currentUser } = useContext(AuthContext);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_CALL}tasks/${currentUser.id}`, config);
        if (response.data.data.length === 0) {
          console.log("No tasks found.");
          return;
        } else {
          const data = response.data.data;
          const updatedTasks = data.map((newTask) => {
            const existingTask = tasks.find((task) => task.id === newTask.id);
            return {
              ...newTask,
              checked: existingTask ? existingTask.checked : false,
              comment: existingTask ? existingTask.comment : "",
              timeSpent: existingTask ? existingTask.timeSpent : 0,
            };
          });
          setTasks(updatedTasks);
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    const fetchActivity = async () => {
      try {
        const currentDate = new Date();
        const formattedDate = currentDate
          .toLocaleDateString("pt-PT", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/\//g, "-");
        const response = await axios.get(`${process.env.REACT_APP_API_CALL}activity/activity/${formattedDate}/${currentUser.id}`, config);
        if (response.data.data.length === 0) {
          console.log("No activity found.");
          return;
        } else {
          const data = response.data.data;

          console.log(response.data.data.end_hour);
          console.log(response.data.data.start_hour);
          console.log(Math.floor((parseInt(response.data.data.end_hour) - parseInt(response.data.data.start_hour)) / 60000))

          setWorkedMinutes(Math.floor((parseInt(response.data.data.end_hour) - parseInt(response.data.data.start_hour)) / 60000));
          console.log(data)
          setActivity(data);
        }
      } catch (error) {
        console.error("Error fetching activity:", error);
      }
    };
    fetchTasks();
    fetchActivity();
  }, []);

  const totalTimeSpent = tasks.reduce((total, task) => total + task.timeSpent, 0);
  const remainingMinutes = workedMinutes - totalTimeSpent;

  const handleToggle = (taskId) => () => {
    setTasks((prevTasks) => prevTasks.map((task) => (task.id === taskId ? { ...task, checked: !task.checked } : task)));
    setSelectedTasks((prevSelectedTasks) =>
      prevSelectedTasks.includes(taskId) ? prevSelectedTasks.filter((id) => id !== taskId) : [...prevSelectedTasks, taskId]
    );
  };

  const handleTaskClick = (taskId) => () => {
    handleToggle(taskId)();
  };

  const handleTimerClick = (taskId) => () => {
    setOpenSliderForTask(openSliderForTask === taskId ? null : taskId);
    setOpenCommentForTask(null);
  };

  const handleCommentClick = (taskId) => () => {
    setOpenCommentForTask(openCommentForTask === taskId ? null : taskId);
    setOpenSliderForTask(null);
  };

  const handleCommentChange = (taskId, event) => {
    const { value } = event.target;
    setComments((prevComments) => ({ ...prevComments, [taskId]: value }));
  };

  const handleTimeChange = (taskId) => (_, newValue) => {
    setTimeSpent((prevTimeSpent) => ({ ...prevTimeSpent, [taskId]: newValue }));
    setTasks((prevTasks) => prevTasks.map((task) => (task.id === taskId ? { ...task, timeSpent: newValue } : task)));
  };

  const handleSaveAll = () => {
    console.log(
      "Selected tasks:",
      selectedTasks.map((taskId) => {
        const task = tasks.find((task) => task.id === taskId);
        return {
          id: taskId,
          title: task.title,
          comment: comments[taskId] || "",
          timeSpent: timeSpent[taskId] || 0,
        };
      })
    );
  };

  return (
    <>
      <Topbar />
      <Container maxWidth="md">
        <Typography variant="h5" gutterBottom>
          Minutos trabalhados não atribuídos: {remainingMinutes} minutos
        </Typography>
        <Typography variant="h5" gutterBottom>
          Quantidade de minutos atribuídos: {totalTimeSpent} minutos
        </Typography>
        <List sx={{ bgcolor: "background.paper", borderRadius: 2 }}>
          {tasks.map((task) => (
            <React.Fragment key={task.id}>
              <ListItem disablePadding>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={task.checked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": `checkbox-list-label-${task.id}` }}
                    sx={{ paddingLeft: 3 }}
                    onClick={handleToggle(task.id)}
                  />
                </ListItemIcon>
                <ListItemText id={`checkbox-list-label-${task.id}`} primary={task.title} />
                {task.checked && (
                  <>
                    <ListItemIcon>
                      <IconButton aria-label="time" onClick={handleTimerClick(task.id)}>
                        <TimerIcon />
                      </IconButton>
                    </ListItemIcon>
                    <ListItemIcon>
                      <IconButton aria-label="comments" onClick={handleCommentClick(task.id)}>
                        <CommentIcon />
                      </IconButton>
                    </ListItemIcon>
                  </>
                )}
              </ListItem>
              {openCommentForTask === task.id && (
                <ListItem disablePadding>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        id={`comment-field-${task.id}`}
                        label="Comment"
                        variant="outlined"
                        fullWidth
                        value={comments[task.id] || ""}
                        onChange={(event) => handleCommentChange(task.id, event)}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              )}
              {openSliderForTask === task.id && (
                <ListItem disablePadding>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <Slider
                        aria-label="Time spent"
                        value={timeSpent[task.id] || 0}
                        onChange={handleTimeChange(task.id)}
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={0}
                        max={Math.min((timeSpent[task.id] || 0) + remainingMinutes, workedMinutes)}
                        style={{ display: openSliderForTask === task.id ? "block" : "none" }}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              )}
            </React.Fragment>
          ))}
        </List>
        <Button
          variant="contained"
          color={selectedTasks.length > 0 ? "error" : "primary"}
          onClick={handleSaveAll}
          disabled={selectedTasks.length === 0}
          sx={{ mt: 2, ml: 2 }}
        >
          Guardar
        </Button>
      </Container>
    </>
  );
};

export default Report;
