import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LoadingScreen from "./scenes/global/LoadingScreen";
import Activity from "./scenes/activity";
import Register from "./scenes/register";
import Login from "./scenes/login";
import Dashboard from "./scenes/dashboard";
import Calendar from "./scenes/calendar";
import CalendarAdmin from "./scenes/calendarAdmin";
import Report from "./scenes/report";
import TasksManager from "./scenes/tasks_manager";
import Management from "./scenes/management";
import LandingPage from "./scenes/landing_page";
import AdminDashboard from "./scenes/admin_dashboard";
import Inventory from "./scenes/inventory";
import Help from "./scenes/help";
import { AuthContext } from "./context/authContext";
import CssBaseline from "@mui/material/CssBaseline";
import { blue } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    background: {
      default: "#cccccc",
      paper: "#cccccc",
      container: "#cccccc"
    },
    primary: {
      main: blue[600],
    },
  },
  typography: {
      fontFamily: [
        'Montserrat',
        'sans-serif',
      ].join(','),
      allVariants: {
        fontFamily: 'Montserrat, sans-serif',
        textTransform: 'none',
        fontSize: 25,
      },
    },
});

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#1976d2',
//     },
//     secondary: {
//       main: '#1976d2',
//     },
//     neutral: {
//       main: '#1976d2',
//     },
//     background: {
//       main: '#1976d2',
//     },
//   },
//   typography: {
//     fontFamily: [
//       'Montserrat',
//       'sans-serif',
//     ].join(','),
//     allVariants: {
//       fontFamily: 'Montserrat, sans-serif',
//       textTransform: 'none',
//       fontSize: 16,
//     },
//   },
// });

function App() {
  const { currentUser } = useContext(AuthContext);

  // if (!currentUser) {
  //   return <LoadingScreen />;
  // } else {

  //   return (
  //     <div className="app">
  //       <Routes>
  //         <Route path="/" element={<Dashboard />} />
  //         <Route path="/register/" element={<Register />} />
  //         <Route path="/login" element={<Login />} />
  //       </Routes>
  //     </div>
  //   );
  // }

  return (
    <ThemeProvider theme={theme}>
      <div className="app">
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admin_dashboard" element={<AdminDashboard />} />
          <Route path="/tasks_manager" element={<TasksManager />} />
          <Route path="/activity" element={<Activity />} />
          <Route path="/register/" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/calendar_admin" element={<CalendarAdmin />} />
          <Route path="/inventory_admin" element={<Inventory />} />
          <Route path="/report" element={<Report />} />
          <Route path="/management" element={<Management />} />
          <Route path="/help" element={<Help />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
