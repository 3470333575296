import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Typography,
  Avatar,
  Container,
  Select,
  MenuItem,
  AlertTitle,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Formik } from "formik";
import * as yup from "yup";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { AuthContext } from "../../context/authContext";
import Topbar from "../global/TopBar";
import { green, grey, red } from "@mui/material/colors";

const TasksManager = () => {
  const taskSchema = yup.object().shape({
    title: yup.string().required("O título da tarefa é obrigatório!"),
    subtitle: yup.string().required("O subtítulo da tarefa é obrigatório!"),
  });

  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [tasks, setTasks] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    } else {
      getEmployees();
      getTasks();
    }
  }, [currentUser]);

  const initialValues = {
    title: "",
    subtitle: "",
    employee_id: 0,
  };

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  const getTasks = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `tasks/`, config);

      console.log(response.data.data);

      if (response.data.data.length === 0) {
        console.log("não há tarefas");
        return;
      } else {
        setTasks(response.data.data);
      }
    } catch (error) {
      console.log(error);
      // logout();
    }
  };

  const getEmployees = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `users/`, config);

      if (response.data.data.length === 0) {
        console.log("não há users");
        return;
      } else {
        console.log(response.data.data);
        setEmployees(response.data.data);
      }
    } catch (error) {
      console.log(error);
      // logout();
    }
  };

  const deleteTask = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_API_CALL + `tasks/${id}`, config);
      console.log("sucesso");
      getTasks();
      // getProject();
      // setOpen(false);
      // setIsLoading(false);
      // setIsEliminated(true);
      // window.scrollTo(0, 0);
    } catch (error) {
      if (error.response.data.auth === false) {
        // setSessionError(true);
        console.log("erro");
      } else {
        // setGeneralError(true);
        console.log(error);
      }
    }
  };

  const handleFormSubmit = async (values) => {
    try {
      let valores = {
        title: values.title,
        subtitle: values.subtitle,
        employee_id: values.employee_id,
      };

      const response = await axios.post(process.env.REACT_APP_API_CALL + "tasks", valores, config);

      if (response.data.success !== 1) {
        console.log("error fatal");
        return;
      } else {
        console.log("success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        // setIsSent(true);
        // setIsNone(false);
        // setErrorMessagePassword(false);
        // setErrorMessageUser(false);
        // setIsExpired(false);
        // setTimeout(() => {
        //   login(values);
        // }, 1000);
      }
    } catch (error) {
      if (error.response.data.auth === false) {
        // setSessionError(true);
      } else {
        // setGeneralError(true);
        // console.log(error);
        // logout();
      }
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 80,
      maxWidth: 80,
      flex: 1,
    },
    {
      field: "title",
      headerName: "Título",
      minWidth: 100,
      maxWidth: 100,
      flex: 1,
    },
    {
      field: "subtitle",
      headerName: "Subtítulo",
      minWidth: 100,
      maxWidth: 150,
      flex: 1,
    },
    {
      field: "employee_id",
      headerName: "Atribuída a:",
      minWidth: 125,
      maxWidth: 1125,
      flex: 1,
      valueGetter: (params) => {
        console.log(params)
        const employeeId = params;
        const employee = employeeId ? employees.find((emp) => emp.id === employeeId) : null;
        return employee ? employee.email : "No employee assigned";
      },
    },
    {
      field: "edit",
      headerName: "",
      minWidth: 95,
      maxWidth: 95,
      flex: 1,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
            backgroundColor={green}
            component={Link}
            to={`/tasks/${params.row.id}`}
            sx={{
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            <PreviewOutlinedIcon sx={{ textDecoration: "none", color: grey }} />
            <Typography color={grey} sx={{ ml: "5px", textDecoration: "none", mt: "1px" }}>
              Editar
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      minWidth: 105,
      maxWidth: 105,
      flex: 1,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
            backgroundColor={red}
            onClick={() => {
              deleteTask(params.row.id);
            }}
            sx={{ cursor: "pointer" }}
          >
            <DeleteOutlineOutlinedIcon />
            <Typography color={grey} sx={{ ml: "5px", textDecoration: "none" }}>
              Eliminar
            </Typography>
          </Box>
        );
      },
    },
  ];

  if (employees.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Topbar />
      <Container component="main" maxWidth="xs">
        <Box display="flex" flexDirection="column" alignItems="center">
          <>
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <TaskOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h2">
              REGISTO DE TAREFAS
            </Typography>

            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={taskSchema}
              validateOnChange={false}
              validateOnBlur={true}
            >
              {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    fullWidth
                    variant="filled"
                    type="text"
                    label={"Título da Tarefa"}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    name="title"
                    error={!!touched.title && !!errors.title}
                    helperText={touched.title && errors.title}
                    inputProps={{ style: { fontSize: 16 } }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    // sx={style}
                  />

                  <TextField
                    margin="normal"
                    fullWidth
                    variant="filled"
                    type="text"
                    label={"Subtítulo da tarefa"}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.subtitle}
                    name="subtitle"
                    error={!!touched.subtitle && !!errors.subtitle}
                    helperText={touched.subtitle && errors.subtitle}
                    inputProps={{ style: { fontSize: 16 } }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    // sx={style}
                  />

                  <Select
                    fullWidth
                    hiddenLabel
                    label={"Escolher empregado:"}
                    variant="filled"
                    labelId="employees"
                    id="employees"
                    value={values.employee_id}
                    onChange={handleChange("employee_id")}
                    sx={{
                      fontSize: "16px",
                      height: "94%",
                    }}
                  >
                    {employees.map((employee) => (
                      <MenuItem key={employee.id} value={employee.id} sx={{ fontSize: "16px" }}>
                        {employee.email}
                      </MenuItem>
                    ))}
                  </Select>

                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    fullWidth
                    sx={{
                      mt: 3,
                      mb: 2,
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold">
                      Adicionar Tarefa
                    </Typography>
                  </Button>
                </form>
              )}
            </Formik>
          </>
          <Box m="40px 0 0 0" height="75vh" width="100vh">
            <DataGrid
              rowsPerPageOptions={[50, 100, 250, 500, 1000]}
              hideFooterSelectedRowCount
              rows={tasks}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default TasksManager;
