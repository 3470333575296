import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../context/authContext";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import {
  Avatar,
  Box,
  Container,
  Button,
  Typography,
  useTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ListItemText,
  IconButton,
  List,
  ListItem,
  Grid,
  Slider,
  ListItemIcon,
  Checkbox,
  Alert,
  AlertTitle,
  Backdrop,
  Paper,
  CircularProgress,
  Divider,
  ListItemAvatar,
  Chip,
} from "@mui/material";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import DoorFrontOutlinedIcon from "@mui/icons-material/DoorFrontOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import AvTimerOutlinedIcon from "@mui/icons-material/AvTimerOutlined";
import CommentIcon from "@mui/icons-material/Comment";
import TimerIcon from "@mui/icons-material/Timer";
import Topbar from "../global/TopBar";

const Activity = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [userLocation, setUserLocation] = useState(null);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [locationTimestamp, setLocationTimestamp] = useState(null);
  const [locationError, setLocationError] = useState(false);
  const [status, setStatus] = useState(null);
  const [isLate, setIsLate] = useState(false);
  const [lateTime, setLateTime] = useState("");
  const [isOnTime, setIsOnTime] = useState(false);
  const [onTimeMessage, setOnTimeMessage] = useState("");
  const [isUnfinished, setIsUnfinished] = useState(null);
  const [workedHours, setWorkedHours] = useState("");
  const [managementData, setManagementData] = useState([]);
  const [calendarType, setCalendarType] = useState("");
  const [selectedWorkType, setSelectedWorkType] = useState("");
  const [selectedLunchTime, setSelectedLunchTime] = useState("");
  const [selectedLeaveTime, setSelectedLeaveTime] = useState("");
  const [selectedDoctorTime, setSelectedDoctorTime] = useState("");
  const [workTypeSelected, setWorkTypeSelected] = useState(false);
  const [lunchTimeSelected, setLunchTimeSelected] = useState(false);
  const [leaveTimeSelected, setLeaveTimeSelected] = useState(false);
  const [doctorTimeSelected, setDoctorTimeSelected] = useState(false);
  const [startComment, setStartComment] = useState("");
  const [endComment, setEndComment] = useState("");
  const [workedMinutes, setWorkedMinutes] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [activity, setActivity] = useState([]);
  const [comments, setComments] = useState({});
  const [timeSpent, setTimeSpent] = useState({});
  const [openSliderForTask, setOpenSliderForTask] = useState(null);
  const [openCommentForTask, setOpenCommentForTask] = useState(null);
  const [entranceTime, setEntranceTime] = useState({ hour: 9, minute: 0 });
  const [loading, setLoading] = useState(true);
  const [isDone, setIsDone] = useState(false);
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState([]);
  const [bankText, setBankText] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [calculatedBank, setCalculatedBank] = useState(null);
  const [bankMinutes, setBankMinutes] = useState(null);
  const [location, setLocation] = useState(null);
  const [isBig, setIsBig] = useState(false);
  const [error, setError] = useState(null);
  const { currentUser } = useContext(AuthContext);

  const maxChars = 100;

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const currentDate = new Date();
  const formattedDate = currentDate
    .toLocaleDateString("pt-PT", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/\//g, "-");

  const totalTimeSpent = tasks.reduce((total, task) => total + task.timeSpent, 0);
  const remainingMinutes = workedMinutes - activity.lunch_time - activity.leave_time - activity.doctor_time - totalTimeSpent;

  const checkManagementInfo = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `management/${currentUser.id}`, config);
      setManagementData(response.data.data);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const checkFirstTimer = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `activity/${currentUser.id}`, config);
      if (response.data.data.length === 0) {
        console.log("First day on the job!");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  };

  const checkActivity = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_CALL}activity/activity/${formattedDate}/${currentUser.id}`, config);

      if (response.data && Array.isArray(response.data.data) && response.data.data.length > 0) {
        const finished = response.data.data[0].finished;
        setWorkedMinutes(Math.floor((parseInt(response.data.data[0].end_hour) - parseInt(response.data.data[0].start_hour)) / 60000));
        setActivity(response.data.data[0]);
        if (finished === 3) {
          setAlert({ severity: "info", title: "Aviso!", message: "Já registou a atividade para hoje! Até amanhã!" });
          setOpen(true);
          setTimeout(() => {
            navigate("/");
          }, 4000);
        } else if (finished === 2) {
          setStatus(2);
          fetchTasks();
        } else if (finished === 1) {
          setStatus(1);
        } else {
          setStatus(0);
        }
      } else {
        setStatus(0);
      }
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_CALL}tasks/${currentUser.id}`, config);
      if (response.data.data.length === 0) {
        console.log("No tasks found.");
        return;
      } else {
        const data = response.data.data;
        const updatedTasks = data.map((newTask) => {
          const existingTask = tasks.find((task) => task.id === newTask.id);
          return {
            ...newTask,
            checked: existingTask ? existingTask.checked : false,
            comment: existingTask ? existingTask.comment : "",
            timeSpent: existingTask ? existingTask.timeSpent : 0,
          };
        });
        setTasks(updatedTasks);
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
      setError(error);
    }
  };

  const checkInventory = async () => {
    setLoading(true);
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `inventory/${currentUser.id}`, config);
      setInventoryData(response.data.data[0]);
      setLoading(false);
    } catch (error) {
      console.log("Erro: " + error);
      setError(error);
      setLoading(false);
    }
  };

  const checkCalendar = async () => {
    setLoading(true);
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `calendar/id/${currentUser.id}`, config);

      // Get current date at midnight
      const now = new Date();
      const currentDateAtMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime() / 1000;

      // Find data for the current day
      const selectedDayData = response.data.data.find((item) => {
        const itemDate = new Date(item.date * 1000); // Convert to milliseconds
        const itemDateAtMidnight = new Date(itemDate.getFullYear(), itemDate.getMonth(), itemDate.getDate()).getTime() / 1000;
        return itemDateAtMidnight === currentDateAtMidnight;
      });

      if (!selectedDayData) {
        console.log("No data found for the selected day.");
        return; // or handle as necessary
      }

      if (selectedDayData.type === "weekend") {
        setAlert({ severity: "info", title: "Aviso!", message: "Não pode registar atividade num fim de semana. Bom fim de semana!" });
        setOpen(true);
        setTimeout(() => {
          navigate("/");
        }, 5000);
        setLoading(false);
      } else if (selectedDayData.type === "holyday") {
        setAlert({ severity: "info", title: "Aviso!", message: "Não pode registar atividade num feriado. Bom feriado!" });
        setOpen(true);
        setTimeout(() => {
          navigate("/");
        }, 5000);
        setLoading(false);
      } else if (selectedDayData.type === "vacation") {
        setAlert({ severity: "info", title: "Aviso!", message: "Não pode registar atividade em período de férias. Boas férias!" });
        setOpen(true);
        setTimeout(() => {
          navigate("/");
        }, 5000);
        setLoading(false);
      } else if (selectedDayData.type === "off_day") {
        setAlert({ severity: "info", title: "Aviso!", message: "Não pode registar atividade em dias tirados. Bom descanso!" });
        setOpen(true);
        setTimeout(() => {
          navigate("/");
        }, 5000);
        setLoading(false);
      } else {
        setCalendarType(selectedDayData.type);
      }
    } catch (error) {
      console.log("Erro: " + error);
      setError(error);
      setLoading(false);
    }
  };

  const getUserLocation = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: "geolocation" });

      if (permissionStatus.state === "granted" || permissionStatus.state === "prompt") {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            try {
              const { latitude, longitude } = position.coords;
              const response = await axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
                params: {
                  latlng: `${latitude},${longitude}`,
                  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                },
              });

              if (response.data && response.data.results.length > 0) {
                setLocation(
                  `${
                    response.data.results[0].address_components[1].short_name
                      ? response.data.results[0].address_components[1].short_name
                      : ""
                  }${
                    response.data.results[0].address_components[2].short_name
                      ? ", " + response.data.results[0].address_components[2].short_name
                      : ""
                  }${
                    response.data.results[0].address_components[3].short_name
                      ? ", " + response.data.results[0].address_components[3].short_name
                      : ""
                  }${
                    response.data.results[0].address_components[4].short_name
                      ? ", " + response.data.results[0].address_components[4].short_name
                      : ""
                  }${
                    response.data.results[0].address_components[5].short_name
                      ? ", " + response.data.results[0].address_components[5].short_name
                      : ""
                  }${
                    response.data.results[0].address_components[6].short_name
                      ? ", " + response.data.results[0].address_components[6].short_name
                      : ""
                  }`
                );
                setUserLocation({ latitude, longitude });
                setLocationTimestamp(position.timestamp);
                handleTime(position.timestamp, latitude, longitude);
              } else {
                throw new Error("Location details not found");
              }
            } catch (error) {
              console.error("Error fetching location:", error);
              setError(error);
            }
          },
          (error) => {
            console.error("Error getting user location:", error);
            setLocationError(true);
          }
        );
      } else {
        console.error("Geolocation permission denied or unavailable.");
        setPermissionDenied(true);
      }
    } catch (error) {
      console.error("Error checking geolocation permission:", error);
      setPermissionDenied(true);
    }
  };

  // Example function to fetch location info
  // const fetchLocationInfo = async (latitude, longitude) => {
  //   try {
  //     const response = await axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
  //       params: {
  //         latlng: `${latitude},${longitude}`,
  //         key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  //       },
  //     });
  //     if (response.data && response.data.results.length > 0) {
  //       setLocation(
  //         `${response.data.results[0].address_components[1].short_name ? response.data.results[0].address_components[1].short_name : ""}${
  //           response.data.results[0].address_components[2].short_name
  //             ? ", " + response.data.results[0].address_components[2].short_name
  //             : ""
  //         }${
  //           response.data.results[0].address_components[3].short_name
  //             ? ", " + response.data.results[0].address_components[3].short_name
  //             : ""
  //         }${
  //           response.data.results[0].address_components[4].short_name
  //             ? ", " + response.data.results[0].address_components[4].short_name
  //             : ""
  //         }${
  //           response.data.results[0].address_components[5].short_name
  //             ? ", " + response.data.results[0].address_components[5].short_name
  //             : ""
  //         }${
  //           response.data.results[0].address_components[6].short_name
  //             ? ", " + response.data.results[0].address_components[6].short_name
  //             : ""
  //         }`
  //       );
  //     } else {
  //       throw new Error("Location details not found");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching location:", error);
  //     setError(error);
  //     // Handle error here
  //   }
  // };

  const handleTime = async (time, lat, lon) => {
    try {
      if (status === 0) {
        isLateToWork(time);
      } else if (status === 1) {
        CalculateWorkDone(time);
      } else if (status === 2) {
        console.log("status 2");
      }
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const handleFormSubmit = async () => {
    try {
      if (status === 0 && isOnTime === false && isLate === true) {
        let valores = {
          employee_id: currentUser.id,
          date: formattedDate,
          work_type: selectedWorkType,
          start_hour: locationTimestamp,
          start_latitude: userLocation.latitude,
          start_longitude: userLocation.longitude,
          start_location: location,
          start_comment: startComment.replace(/["']/g, ""),
          end_hour: "",
          end_latitude: "",
          end_longitude: "",
          end_location: "",
          end_comment: null,
          lunch_time: null,
          leave_time: null,
          doctor_time: null,
          finished: 1,
          report: null,
        };

        const response = await axios.post(process.env.REACT_APP_API_CALL + `activity/`, valores, config);
        if (response.data.success === 1) {
          let values = {
            id: inventoryData.id,
            employee_id: currentUser.id,
            bank: inventoryData.bank,
            tokens: inventoryData.tokens - 1,
          };
          const response2 = await axios.patch(process.env.REACT_APP_API_CALL + `inventory`, values, config);

          if (response2.data.success === 1) {
            let val = {
              employee_id: currentUser.id,
              date: formattedDate,
              type: "entry_token",
              value: -1,
            };
            const response3 = await axios.post(process.env.REACT_APP_API_CALL + `history`, val, config);

            if (response3.data.success === 1) {
              setAlert({ severity: "success", title: "Sucesso!", message: "Registo efetuado com sucesso!" });
              handleOpen();
            }
          }
        }
      } else if (status === 0 && isOnTime === true && isLate === false) {
        let valores = {
          employee_id: currentUser.id,
          date: formattedDate,
          work_type: selectedWorkType,
          start_hour: locationTimestamp,
          start_latitude: userLocation.latitude,
          start_longitude: userLocation.longitude,
          start_location: location,
          start_comment: null,
          end_hour: "",
          end_latitude: "",
          end_longitude: "",
          end_location: "",
          end_comment: null,
          lunch_time: null,
          leave_time: null,
          doctor_time: null,
          finished: 1,
          report: null,
        };

        const response = await axios.post(process.env.REACT_APP_API_CALL + `activity/`, valores, config);

        if (response.data.success === 1) {
          setAlert({ severity: "success", title: "Sucesso!", message: "Registo efetuado com sucesso!" });
          handleOpen();
        }
      } else if (status === 1 && isUnfinished === true && isDone === false) {
        const response2 = await axios.get(process.env.REACT_APP_API_CALL + `activity/activity/${formattedDate}/${currentUser.id}`, config);

        let valores = {
          id: response2.data.data[0].id,
          employee_id: response2.data.data[0].employee_id,
          date: response2.data.data[0].date,
          work_type: response2.data.data[0].work_type,
          email: response2.data.data[0].email,
          start_hour: response2.data.data[0].start_hour,
          start_latitude: response2.data.data[0].start_latitude,
          start_longitude: response2.data.data[0].start_longitude,
          start_location: response2.data.data[0].start_location,
          start_comment: response2.data.data[0].start_comment,
          end_hour: locationTimestamp,
          end_latitude: userLocation.latitude,
          end_longitude: userLocation.longitude,
          end_location: location,
          end_comment: endComment.replace(/["']/g, ""),
          lunch_time: selectedLunchTime,
          leave_time: selectedLeaveTime,
          doctor_time: selectedDoctorTime,
          finished: 2,
          report: null,
        };

        const response3 = await axios.patch(process.env.REACT_APP_API_CALL + `activity`, valores, config);

        if (response3.data.success === 1) {
          let values = {
            id: inventoryData.id,
            employee_id: currentUser.id,
            bank: calculatedBank,
            tokens: inventoryData.tokens - 1,
          };
          const response4 = await axios.patch(process.env.REACT_APP_API_CALL + `inventory`, values, config);

          if (response4.data.success === 1) {
            let val = {
              employee_id: currentUser.id,
              date: formattedDate,
              type: "exit_token",
              value: -1,
            };
            const response5 = await axios.post(process.env.REACT_APP_API_CALL + `history`, val, config);

            if (response5.data.success === 1) {
              let val2 = {
                employee_id: currentUser.id,
                date: formattedDate,
                type: "exit_bank",
                value: bankMinutes,
              };

              const response6 = await axios.post(process.env.REACT_APP_API_CALL + `history`, val2, config);

              if (response6.data.success === 1) {
                setAlert({ severity: "success", title: "Sucesso!", message: "Registo efetuado com sucesso!" });
                handleOpen();
              }
            }
          }
        }
      } else if (status === 1 && isUnfinished === false && isDone === true) {
        const response2 = await axios.get(process.env.REACT_APP_API_CALL + `activity/activity/${formattedDate}/${currentUser.id}`, config);

        let valores = {
          id: response2.data.data[0].id,
          employee_id: response2.data.data[0].employee_id,
          date: response2.data.data[0].date,
          work_type: response2.data.data[0].work_type,
          email: response2.data.data[0].email,
          start_hour: response2.data.data[0].start_hour,
          start_latitude: response2.data.data[0].start_latitude,
          start_longitude: response2.data.data[0].start_longitude,
          start_location: response2.data.data[0].start_location,
          start_comment: response2.data.data[0].start_comment,
          end_hour: locationTimestamp,
          end_latitude: userLocation.latitude,
          end_longitude: userLocation.longitude,
          end_location: location,
          end_comment: null,
          lunch_time: selectedLunchTime,
          leave_time: selectedLeaveTime,
          doctor_time: selectedDoctorTime,
          finished: 2,
          report: null,
        };

        const response = await axios.patch(process.env.REACT_APP_API_CALL + `activity`, valores, config);

        if (response.data.success === 1) {
          let values = {
            id: inventoryData.id,
            employee_id: currentUser.id,
            bank: calculatedBank,
            tokens: inventoryData.tokens,
          };

          const response3 = await axios.patch(process.env.REACT_APP_API_CALL + `inventory`, values, config);

          if (response3.data.success === 1) {
            let val = {
              employee_id: currentUser.id,
              date: formattedDate,
              type: "exit_bank",
              value: bankMinutes,
            };

            const response4 = await axios.post(process.env.REACT_APP_API_CALL + `history`, val, config);

            if (response4.data.success === 1) {
              setAlert({ severity: "success", title: "Sucesso!", message: "Registo efetuado com sucesso! Aguarde 10 segundos!" });
              handleOpen();
            }
          }
        }
      } else if (status === 2) {
        const response2 = await axios.get(process.env.REACT_APP_API_CALL + `activity/activity/${formattedDate}/${currentUser.id}`, config);

        let valores = {
          id: response2.data.data[0].id,
          employee_id: response2.data.data[0].employee_id,
          date: response2.data.data[0].date,
          work_type: response2.data.data[0].work_type,
          email: response2.data.data[0].email,
          start_hour: response2.data.data[0].start_hour,
          start_latitude: response2.data.data[0].start_latitude,
          start_longitude: response2.data.data[0].start_longitude,
          start_location: response2.data.data[0].start_location,
          start_comment: response2.data.data[0].start_comment,
          end_hour: response2.data.data[0].end_hour,
          end_latitude: response2.data.data[0].end_latitude,
          end_longitude: response2.data.data[0].end_longitude,
          end_location: response2.data.data[0].end_location,
          end_comment: response2.data.data[0].end_comment,
          lunch_time: response2.data.data[0].lunch_time,
          leave_time: response2.data.data[0].leave_time,
          doctor_time: response2.data.data[0].doctor_time,
          finished: 3,
          report: selectedTasks.map((taskId) => {
            const task = tasks.find((task) => task.id === taskId);
            return {
              id: taskId,
              title: task.title,
              comment: comments[taskId] || "",
              timeSpent: timeSpent[taskId] || 0,
            };
          }),
        };

        const response = await axios.patch(process.env.REACT_APP_API_CALL + `activity`, valores, config);

        if (response.data.success === 1) {
          setAlert({ severity: "success", title: "Sucesso!", message: "Registo completo!" });
          handleOpen();
        }
      }
    } catch (error) {
      console.log(error);
      setError(error);
      setAlert({ severity: "error", title: "Erro!", message: "Erro de sistema. Por favor, contacte um administrador!" });
      handleOpen();
    }
  };

  const isLateToWork = (timestamp) => {
    const date = new Date(timestamp);
    const currentHour = date.getHours();
    const currentMinute = date.getMinutes();

    const { hour: lateHour, minute: lateMinute } = entranceTime;

    console.log(entranceTime);

    const entranceTimeInMinutes = lateHour * 60 + lateMinute;
    const currentTimeInMinutes = currentHour * 60 + currentMinute;

    const gracePeriodEndTime = entranceTimeInMinutes + 15;

    if (currentTimeInMinutes > gracePeriodEndTime) {
      const totalLateMinutes = currentTimeInMinutes - entranceTimeInMinutes;
      const hoursLate = Math.floor(totalLateMinutes / 60);
      const minutesLate = totalLateMinutes % 60;

      let hourString = hoursLate === 1 ? "hora" : "horas";
      let minuteString = minutesLate === 1 ? "minuto" : "minutos";

      if (hoursLate === 0) {
        setIsLate(true);
        setIsOnTime(false);
        setLateTime(`Está atrasado(a) ${minutesLate} ${minuteString}!`);
        setOnTimeMessage("");
      } else if (minutesLate === 0) {
        setIsLate(true);
        setIsOnTime(false);
        setLateTime(`Está atrasado(a) ${hoursLate} ${hourString}!`);
        setOnTimeMessage("");
      } else {
        setIsLate(true);
        setIsOnTime(false);
        setLateTime(`Está atrasado(a) ${hoursLate} ${hourString} e ${minutesLate} ${minuteString}!`);
        setOnTimeMessage("");
      }
    } else {
      setIsLate(false);
      setIsOnTime(true);
      setLateTime("");
      setOnTimeMessage("Registo efetuado! Bom trabalho!");
    }
  };

  const CalculateWorkDone = async (timestamp) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `activity/activity/${formattedDate}/${currentUser.id}`, config);
      const workType = response.data.data[0].work_type; // Store work_type in a variable
      setSelectedWorkType(workType); // Update state if needed

      const selectedManagementData = managementData.find((data) => data.work_type === workType);

      const minimumHoursInMillis = selectedManagementData ? selectedManagementData.minimum_hours * 3600000 : 0;

      const startHourMillis = parseInt(response.data.data[0].start_hour);

      const reportTime = 10;

      const workedTimeMillis =
        timestamp -
        startHourMillis -
        selectedLunchTime * 60000 -
        selectedLeaveTime * 60000 -
        selectedDoctorTime * 60000 +
        reportTime * 60000;
      // console.log("timestamp:", timestamp);
      // console.log("startHourMillis:", startHourMillis);
      // console.log("selectedLunchTime:", selectedLunchTime);
      // console.log("selectedLeaveTime:", selectedLeaveTime);
      // console.log("selectedDoctorTime:", selectedDoctorTime);
      // console.log("workedTimeMillis:", workedTimeMillis);

      let minimumHoursInMinutes;

      if (calendarType === "off_day_afternoon" || calendarType === "off_day_morning") {
        minimumHoursInMinutes = Math.floor(selectedManagementData && (selectedManagementData.minimum_hours - 4) * 60);
      } else if (calendarType === "custom_afternoon_1" || calendarType === "custom_morning_1") {
        minimumHoursInMinutes = Math.floor(selectedManagementData && (selectedManagementData.minimum_hours - 1) * 60);
      } else if (calendarType === "custom_afternoon_2" || calendarType === "custom_morning_2") {
        minimumHoursInMinutes = Math.floor(selectedManagementData && (selectedManagementData.minimum_hours - 2) * 60);
      } else if (calendarType === "custom_afternoon_3" || calendarType === "custom_morning_3") {
        minimumHoursInMinutes = Math.floor(selectedManagementData && (selectedManagementData.minimum_hours - 3) * 60);
      } else {
        minimumHoursInMinutes = Math.floor(selectedManagementData && selectedManagementData.minimum_hours * 60);
      }

      const workedTimeInMinutes = Math.floor(workedTimeMillis / 60000);

      // Calculate excess minutes
      const excessMinutes = workedTimeInMinutes - minimumHoursInMinutes;

      // Check if excess minutes are negative
      if (excessMinutes < 0) {
        setIsBig(false);
        // Calculate absolute value of excess minutes
        const absoluteExcessMinutes = Math.abs(excessMinutes);

        // Round absolute excess minutes up to nearest multiple of 15
        const roundedExcessMinutes = Math.ceil(absoluteExcessMinutes / 15) * 15;

        const bankTextHours = Math.floor(roundedExcessMinutes / 60);
        const bankTextMinutes = roundedExcessMinutes % 60;

        setCalculatedBank(inventoryData.bank - roundedExcessMinutes);
        setBankMinutes(-roundedExcessMinutes);

        // Construct bank text for negative excess minutes
        if (bankTextHours === 1 && bankTextMinutes > 0) {
          setBankText(`Foi retirada ${bankTextHours} hora e ${bankTextMinutes} minutos do seu banco de horas.`);
        } else if (bankTextHours === 1 && bankTextMinutes === 0) {
          setBankText(`Foi retirada ${bankTextHours} hora do seu banco de horas.`);
        } else if (bankTextHours === 0 && bankTextMinutes > 0) {
          setBankText(`Foram retirados ${bankTextMinutes} minutos do seu banco de horas.`);
        } else if (bankTextHours > 0 && bankTextMinutes === 0) {
          setBankText(`Foram retiradas ${bankTextHours} horas do seu banco de horas.`);
        } else if (bankTextHours > 0 && bankTextMinutes > 0) {
          setBankText(`Foram retiradas ${bankTextHours} horas e ${bankTextMinutes} minutos do seu banco de horas.`);
        } else {
          setBankText("Não foram retirados minutos do seu banco de horas.");
        }
      } else {
        setIsBig(true);
        // Calculate excess minutes in intervals of 15
        const excessMinutesInIntervals = Math.floor(excessMinutes / 15) * 15;
        const bankTextHours = Math.floor(excessMinutesInIntervals / 60);
        const bankTextMinutes = excessMinutesInIntervals % 60;

        setCalculatedBank(inventoryData.bank + excessMinutesInIntervals);
        setBankMinutes(excessMinutesInIntervals);

        // Construct bank text for positive excess minutes
        if (bankTextHours === 1 && bankTextMinutes > 0) {
          setBankText(`Armazenou ${bankTextHours} hora e ${bankTextMinutes} minutos no seu banco de horas.`);
        } else if (bankTextHours === 1 && bankTextMinutes === 0) {
          setBankText(`Armazenou ${bankTextHours} hora no seu banco de horas.`);
        } else if (bankTextHours === 0 && bankTextMinutes > 0) {
          setBankText(`Armazenou ${bankTextMinutes} minutos no seu banco de horas.`);
        } else if (bankTextHours > 0 && bankTextMinutes > 0) {
          setBankText(`Armazenou ${bankTextHours} horas e ${bankTextMinutes} minutos no seu banco de horas.`);
        } else if (bankTextHours === 0 && bankTextMinutes === 0) {
          setBankText("Armazenou 0 minutos no seu banco de horas.");
        } else if (bankTextHours > 0 && bankTextMinutes === 0) {
          setBankText(`Armazenou ${bankTextHours} horas no seu banco de horas.`);
        } else {
          setBankText("Não armazenou minutos no seu banco de horas.");
        }
      }

      const workedHours = Math.floor(workedTimeMillis / (1000 * 60 * 60), 0);
      const workedMinutes = Math.floor((workedTimeMillis % (1000 * 60 * 60)) / (1000 * 60), 0);

      const hourText = workedHours === 1 ? "hora" : "horas";
      const minuteText = workedMinutes === 1 ? "minuto" : "minutos";

      setWorkedHours(`Trabalhou ${workedHours} ${hourText} e ${workedMinutes} ${minuteText}!`);

      if (workedTimeMillis < minimumHoursInMillis) {
        setIsUnfinished(true);
        setIsDone(false);
        console.log("NÃO FEZ AS HORAS TODAS");
      } else {
        setIsUnfinished(false);
        setIsDone(true);
        handleFormSubmit();
        console.log("FEZ AS HORAS E MAIS ALGUMAS");
      }
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const handleWorkTypeChange = (event) => {
    if (status === 0) {
      const workType = event.target.value;
      setSelectedWorkType(workType);
      setWorkTypeSelected(true);

      const selectedWork = managementData.find((data) => data.work_type === workType);
      if (selectedWork) {
        const [hour, minute] = selectedWork.entrance_time.split(":").map(Number);
        if (calendarType === "off_day_morning") {
          setEntranceTime({ hour: hour + 5, minute: minute });
        } else if (calendarType === "custom_morning_1") {
          setEntranceTime({ hour: hour + 1, minute: minute });
        } else if (calendarType === "custom_morning_2") {
          setEntranceTime({ hour: hour + 2, minute: minute });
        } else if (calendarType === "custom_morning_3") {
          setEntranceTime({ hour: hour + 3, minute: minute });
        } else {
          setEntranceTime({ hour, minute });
        }
      }
    }
  };

  const handleLunchTimeChange = (event) => {
    setSelectedLunchTime(event.target.value);
    setLunchTimeSelected(true);
  };

  const handleLeaveTimeChange = (event) => {
    setSelectedLeaveTime(event.target.value);
    setLeaveTimeSelected(true);
  };

  const handleDoctorTimeChange = (event) => {
    setSelectedDoctorTime(event.target.value);
    setDoctorTimeSelected(true);
  };

  const handleToggle = (taskId) => () => {
    setTasks((prevTasks) => prevTasks.map((task) => (task.id === taskId ? { ...task, checked: !task.checked } : task)));
    setSelectedTasks((prevSelectedTasks) =>
      prevSelectedTasks.includes(taskId) ? prevSelectedTasks.filter((id) => id !== taskId) : [...prevSelectedTasks, taskId]
    );
    setOpenCommentForTask(null);
    setOpenSliderForTask(null);
  };

  const handleTimerClick = (taskId) => () => {
    setOpenSliderForTask(openSliderForTask === taskId ? null : taskId);
    setOpenCommentForTask(null);
  };

  const handleCommentClick = (taskId) => () => {
    setOpenCommentForTask(openCommentForTask === taskId ? null : taskId);
    setOpenSliderForTask(null);
  };

  const handleCommentChange = (taskId, event) => {
    const { value } = event.target;
    setComments((prevComments) => ({ ...prevComments, [taskId]: value.replace(/["']/g, "") }));
  };

  const handleTimeChange = (taskId) => (_, newValue) => {
    setTimeSpent((prevTimeSpent) => ({ ...prevTimeSpent, [taskId]: newValue }));
    setTasks((prevTasks) => prevTasks.map((task) => (task.id === taskId ? { ...task, timeSpent: newValue } : task)));
  };

  const handleOpen = () => {
    setOpen(true);
    setTimeout(() => {
      window.location.reload();
    }, 10000);
  };

  useEffect(() => {
    if (!currentUser && currentUser.email === null) {
      navigate("/login");
    } else {
      checkFirstTimer();
      checkActivity();
      checkManagementInfo();
      checkInventory();
      checkCalendar();
    }
  }, [currentUser]);

  useEffect(() => {
    if (isOnTime || (isDone && !isUnfinished)) {
      handleFormSubmit();
    }
  }, [isOnTime, isDone]);

  if (loading) {
    return (
      <>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }

  return (
    <>
      <Topbar />
      <Container component="main" maxWidth="xs">
        <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
            <Grid container sx={{ bgcolor: "darkgrey", borderRadius: 2 }}>
              <Grid item xs={12}>
                <Alert severity={alert.severity}>
                  <AlertTitle>{alert.title}</AlertTitle>
                  {alert.message}
                </Alert>
              </Grid>

              {isOnTime && (
                <Grid item xs={12} sx={{ bgcolor: "green", mt: 2 }}>
                  <Typography component="h1" variant="h4" color="success.main" fontWeight="bold">
                    {onTimeMessage}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12} sx={{ bgcolor: "#ffcc80", mt: 2 }}>
                <Typography component="h1" variant="h4" color="black" fontWeight="bold">
                  {workedHours}
                </Typography>
              </Grid>

              {bankText !== "" && (
                <Grid item xs={12} sx={{ bgcolor: isBig === true ? "darkgreen" : "darkred", mt: 2 }}>
                  <Typography component="h1" variant="h4" color="white" fontWeight="bold">
                    {bankText}
                  </Typography>
                </Grid>
              )}

              {isDone && (
                <Grid item xs={12} sx={{ bgcolor: "#ffcc80", mt: 2 }}>
                  <Typography component="h1" variant="h4" color="white" fontWeight="bold">
                    Dia de trabalho fechado! Preencha o relatório para terminar o processo!
                  </Typography>
                </Grid>
              )}

              {inventoryData && isLate && (
                <Grid item xs={12} sx={{ bgcolor: "darkred", mt: 2 }}>
                  <Typography component="h1" variant="h4" color="white" fontWeight="bold">
                    Perdeu um token, ficando com {inventoryData.tokens - 1} no total.
                  </Typography>
                </Grid>
              )}

              {inventoryData && isUnfinished && (
                <Grid item xs={12} sx={{ bgcolor: "darkred", mt: 2 }}>
                  <Typography component="h1" variant="h4" color="white" fontWeight="bold">
                    Perdeu um token, ficando com {inventoryData.tokens - 1} no total.
                  </Typography>
                </Grid>
              )}

              {permissionDenied && (
                <Grid item xs={12}>
                  <Alert severity="error" sx={{ mt: 2 }}>
                    Acesso à localização negado. Por favor, altere as suas definições no browser.
                  </Alert>
                </Grid>
              )}

              {userLocation && isLoaded && location && (
                <Box>
                  <Grid item xs={12} sx={{ bgcolor: "white", mt: 2 }}>
                    <Typography variant="body1" color="black" fontWeight="bold" gutterBottom>
                      Data e Hora de Registo: {new Date(locationTimestamp).toLocaleString()}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sx={{ bgcolor: "white", mt: 2 }}>
                    <Typography variant="h6" color="black">
                      A sua localização:
                    </Typography>
                    <Box>
                      <Paper elevation={3} sx={{ width: "100%", height: 400 }}>
                        <GoogleMap
                          id="marker-example"
                          mapContainerStyle={{ height: "100%", width: "100%" }}
                          zoom={15}
                          center={{ lat: userLocation.latitude, lng: userLocation.longitude }}
                          onLoad={(map) => {
                            const contentString = `
                          <div>
                            <h3>A sua Localização</h3>
                            <p>Latitude: ${userLocation.latitude}</p>
                            <p>Longitude: ${userLocation.longitude}</p>
                          </div>
                        `;
                            const infowindow = new window.google.maps.InfoWindow({ content: contentString });
                            const marker = new window.google.maps.Marker({
                              position: new window.google.maps.LatLng(userLocation.latitude, userLocation.longitude),
                              map,
                              title: "A sua localização",
                            });
                            marker.addListener("click", async () => {
                              infowindow.setContent(`
          <div>
            <h3>A sua localização</h3>
            <p>Latitude: ${userLocation.latitude}</p>
            <p>Longitude: ${userLocation.longitude}</p>
            <p>Detalhes: ${location}</p>
          </div>
        `);
                              infowindow.open(map, marker);
                            });
                          }}
                        />
                      </Paper>
                    </Box>
                  </Grid>
                </Box>
              )}
            </Grid>
          </Backdrop>

          {error ? (
            <Typography variant="h6" color="error">
              Error: {error.message}
            </Typography>
          ) : (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12}>
                <br></br>
                {status === 0 &&
                  (locationError === false ? (
                    <>
                      <Avatar sx={{ margin: "auto", bgcolor: "secondary.main" }}>
                        <MeetingRoomOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h2" fontWeight={"bold"}>
                        Registar Entrada
                      </Typography>
                      <br></br>
                      {(isLate && !isOnTime) || (!isLate && isOnTime) ? null : (
                        <>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id="work-type-label">Tipo de Trabalho</InputLabel>
                              <Select
                                labelId="work-type-label"
                                id="work-type-select"
                                value={selectedWorkType}
                                onChange={handleWorkTypeChange}
                                label="Tipo de Trabalho"
                              >
                                {managementData.map((data) => (
                                  <MenuItem key={data.id} value={data.work_type}>
                                    {data.work_type}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12}>
                            <Button
                              onClick={getUserLocation}
                              color="secondary"
                              variant="contained"
                              fullWidth
                              sx={{ mt: 3, mb: 2 }}
                              disabled={!workTypeSelected}
                            >
                              <Typography variant="h4" fontWeight="bold">
                                Entrar
                              </Typography>
                            </Button>
                          </Grid>
                        </>
                      )}
                      {isLate && (
                        <>
                          <Grid item xs={12}>
                            <Typography component="h1" variant="h4" color="error" fontWeight="bold">
                              {lateTime}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              label="Justificação Atraso"
                              multiline
                              rows={4}
                              value={startComment}
                              onChange={(e) => setStartComment(e.target.value)}
                              fullWidth
                              margin="normal"
                            />
                            <Typography
                              variant="body2"
                              style={{
                                color: startComment.length > maxChars ? "red" : "inherit",
                                fontWeight: startComment.length > maxChars ? "bold" : "",
                                marginTop: "4px",
                              }}
                            >
                              {startComment.length}/{maxChars} caracteres
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Button
                              onClick={handleFormSubmit}
                              color="secondary"
                              variant="contained"
                              fullWidth
                              sx={{ mt: 3, mb: 2 }}
                              disabled={!startComment || startComment.length > maxChars}
                            >
                              <Typography variant="h4" fontWeight="bold">
                                Registar Entrada
                              </Typography>
                            </Button>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography component="h1" variant="h2">
                        Erro, por favor contacte um administrador!
                      </Typography>
                    </>
                  ))}
                {status === 1 && (
                  <>
                    <Avatar sx={{ margin: "auto", bgcolor: "secondary.main" }}>
                      <DoorFrontOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h2" fontWeight={"bold"}>
                      Registar Saída
                    </Typography>
                    <br></br>

                    <Divider>
                      <Chip
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                        label={"Tempo de Almoço (minutos)"}
                        size="large"
                        color="primary"
                      />
                    </Divider>
                    <br></br>
                    {(isDone && !isUnfinished) || (!isDone && isUnfinished) ? null : (
                      <>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id="lunch-time-label">Escolha uma opção</InputLabel>
                            <Select
                              labelId="lunch-time-label"
                              id="lunch-time-select"
                              value={selectedLunchTime}
                              onChange={handleLunchTimeChange}
                              label="Escolha uma opção"
                            >
                              {calendarType === "off_day_morning" && <MenuItem value={0}>Sem almoço</MenuItem>}
                              {calendarType !== "off_day_morning" && <MenuItem value={30}>30 minutos</MenuItem>}
                              {calendarType !== "off_day_morning" && <MenuItem value={45}>45 minutos</MenuItem>}
                              {calendarType !== "off_day_morning" && <MenuItem value={60}>60 minutos</MenuItem>}
                            </Select>
                            <br></br>
                          </FormControl>

                          {calendarType === "off_day_morning" && (
                            <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }} fontWeight={"bold"}>
                              Como tirou a manhã toda, assume-se que almoçou antes de iniciar o seu horário de trabalho! Escolha a opção
                              "Sem Almoço" para continuar!
                            </Typography>
                          )}
                        </Grid>

                        <Divider>
                          <Chip
                            sx={{
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                            label={"Saída Trabalho (minutos)"}
                            size="large"
                            color="primary"
                          />
                        </Divider>

                        <Grid item xs={12} sx={{ mt: 3 }}>
                          <FormControl fullWidth>
                            <InputLabel id="leave-time-label">Escolha uma opção</InputLabel>
                            <Select
                              labelId="leave-time-label"
                              id="leave-time-select"
                              value={selectedLeaveTime}
                              onChange={handleLeaveTimeChange}
                              label="Escolha uma opção"
                            >
                              <MenuItem value={0}>Sem saída</MenuItem>
                              <MenuItem value={30}>30 minutos</MenuItem>
                              <MenuItem value={60}>1 hora</MenuItem>
                              <MenuItem value={90}>1 hora e 30 minutos</MenuItem>
                              <MenuItem value={120}>2 horas</MenuItem>
                              <MenuItem value={150}>2 horas e 30 minutos</MenuItem>
                              <MenuItem value={180}>3 horas</MenuItem>
                              <MenuItem value={210}>3 horas e 30 minutos</MenuItem>
                              <MenuItem value={240}>4 horas</MenuItem>
                            </Select>
                            <br></br>
                          </FormControl>

                          <Typography
                            variant="body1"
                            gutterBottom
                            fontSize={"20px"}
                            sx={{ textAlign: "justify" }}
                            fontWeight={"bold"}
                            color={"red"}
                          >
                            SELECIONE A OPÇÃO "SEM SAÍDA" APENAS SE:
                          </Typography>
                          <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }} fontWeight={"bold"}>
                            - Teve de sair do trabalho e não tenciona voltar no dia;
                          </Typography>
                          <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }} fontWeight={"bold"}>
                            - Se não teve qualquer saída de trabalho extraordinária.
                          </Typography>

                          {/* <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                            O tempo de saída de trabalho deverá ser preenchido de forma correta, sempre que necessário a ausência do
                            trabalho por emergência ou por acontecimento inesperado. Este tempo deverá ser compensado durante o dia, ou
                            compensado do banco de horas (perderá um token).
                          </Typography> */}
                        </Grid>
                        <br></br>
                        <Divider>
                          <Chip
                            sx={{
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                            label={"Saída Médica (minutos)"}
                            size="large"
                            color="primary"
                          />
                        </Divider>

                        <Grid item xs={12} sx={{ mt: 3 }}>
                          <FormControl fullWidth>
                            <InputLabel id="doctor-time-label">Escolha uma opção</InputLabel>
                            <Select
                              labelId="doctor-time-label"
                              id="doctor-time-select"
                              value={selectedDoctorTime}
                              onChange={handleDoctorTimeChange}
                              label="Escolha uma opção"
                            >
                              <MenuItem value={0}>Sem saída</MenuItem>
                              <MenuItem value={30}>30 minutos</MenuItem>
                              <MenuItem value={60}>1 hora</MenuItem>
                              <MenuItem value={90}>1 hora e 30 minutos</MenuItem>
                              <MenuItem value={120}>2 horas</MenuItem>
                              <MenuItem value={150}>2 horas e 30 minutos</MenuItem>
                              <MenuItem value={180}>3 horas</MenuItem>
                              <MenuItem value={210}>3 horas e 30 minutos</MenuItem>
                              <MenuItem value={240}>4 horas</MenuItem>
                            </Select>
                            <br></br>
                          </FormControl>

                          {selectedDoctorTime > 0 && (
                            <Typography
                              variant="body1"
                              gutterBottom
                              fontSize={"20px"}
                              sx={{ textAlign: "justify", color: "red" }}
                              fontWeight={"bold"}
                            >
                              DEVERÁ ENVIAR UMA JUSTIFICAÇÃO MÉDICA PARA O EMAIL contabilidade@heaboo.com!
                            </Typography>
                          )}

                          <Typography
                            variant="body1"
                            gutterBottom
                            fontSize={"20px"}
                            sx={{ textAlign: "justify" }}
                            fontWeight={"bold"}
                            color={"red"}
                          >
                            SELECIONE A OPÇÃO "SEM SAÍDA" APENAS SE:
                          </Typography>
                          <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }} fontWeight={"bold"}>
                            - Teve de sair do trabalho e não tenciona voltar no dia;
                          </Typography>
                          <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }} fontWeight={"bold"}>
                            - Se não teve qualquer saída de trabalho por motivos médicos.
                          </Typography>

                          {/* <Typography variant="body1" gutterBottom fontSize={"20px"} sx={{ textAlign: "justify" }}>
                            O tempo de saída de trabalho deverá ser preenchido de forma correta, sempre que necessário a ausência do
                            trabalho por emergência ou por acontecimento inesperado. Este tempo deverá ser compensado durante o dia, ou
                            compensado do banco de horas (perderá um token).
                          </Typography> */}
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            onClick={getUserLocation}
                            color="secondary"
                            variant="contained"
                            fullWidth
                            sx={{ mt: 3, mb: 2 }}
                            disabled={!lunchTimeSelected || !leaveTimeSelected || !doctorTimeSelected}
                          >
                            <Typography variant="h4" fontWeight="bold">
                              Sair
                            </Typography>
                          </Button>
                        </Grid>
                      </>
                    )}
                    {isUnfinished && (
                      <>
                        <Typography component="h1" variant="h2" color="error" fontWeight="bold">
                          Não terminou o seu horário de trabalho!
                        </Typography>
                        <TextField
                          label="Justificação de saída"
                          multiline
                          rows={4}
                          value={endComment}
                          onChange={(e) => setEndComment(e.target.value)}
                          fullWidth
                          margin="normal"
                        />
                        <Typography
                          variant="body2"
                          style={{
                            color: endComment.length > maxChars ? "red" : "inherit",
                            fontWeight: endComment.length > maxChars ? "bold" : "",
                            marginTop: "4px",
                          }}
                        >
                          {endComment.length}/{maxChars} caracteres
                        </Typography>
                        <Button
                          onClick={handleFormSubmit}
                          color="secondary"
                          variant="contained"
                          fullWidth
                          sx={{ mt: 3, mb: 2 }}
                          disabled={!endComment || endComment.length > maxChars}
                        >
                          <Typography variant="h4" fontWeight="bold">
                            Registar Saída
                          </Typography>
                        </Button>
                      </>
                    )}
                  </>
                )}
                {status === 2 && (
                  <>
                    <br></br>
                    <Typography component="h1" variant="h2" fontWeight={"bold"}>
                      Preencha o seu relatório
                    </Typography>
                    <br></br>
                    <Avatar sx={{ margin: "auto", bgcolor: "secondary.main" }}>
                      <AvTimerOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      Minutos Trabalhados
                    </Typography>
                    <br></br>
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6}>
                        <Box
                          sx={{
                            bgcolor: "#ffcc80",
                            p: 2,
                            borderRadius: 2,
                            marginRight: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "75%",
                          }}
                        >
                          <Grid container>
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography variant="h5" fontSize={"16px"}>
                                Não atribuídos
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography variant="h5" fontWeight={"bold"}>
                                {remainingMinutes}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Box
                          sx={{
                            bgcolor: "#81c784",
                            p: 2,
                            borderRadius: 2,
                            marginLeft: 1,
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "75%",
                          }}
                        >
                          <Grid container>
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography variant="h5" fontSize={"16px"}>
                                Atribuídos
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography variant="h5" fontWeight={"bold"}>
                                {totalTimeSpent}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <br></br>
                        <Avatar sx={{ margin: "auto", bgcolor: "secondary.main" }}>
                          <SummarizeOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                          Escolha de tarefas
                        </Typography>
                        <List>
                          {tasks.map((task) => (
                            <React.Fragment key={task.id}>
                              <Divider>
                                {/* <Chip label={"Tarefa " + task.id }size="small" /> */}
                                <Chip size="small" />
                              </Divider>
                              <br></br>

                              <ListItem disablePadding sx={{ bgcolor: "background.paper", borderRadius: 2 }}>
                                <ListItemAvatar>
                                  <Checkbox
                                    edge="start"
                                    checked={task.checked}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": `checkbox-list-label-${task.id}` }}
                                    sx={{ paddingLeft: 3 }}
                                    onClick={handleToggle(task.id)}
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  id={`checkbox-list-label-${task.id}`}
                                  primary={task.title}
                                  secondary={task.subtitle}
                                  primaryTypographyProps={{ fontSize: "20px" }}
                                  secondaryTypographyProps={{ fontSize: "14px" }}
                                />
                                {task.checked && (
                                  <>
                                    <ListItemIcon>
                                      <IconButton aria-label="time" onClick={handleTimerClick(task.id)}>
                                        <TimerIcon />
                                      </IconButton>
                                    </ListItemIcon>
                                    <ListItemIcon>
                                      <IconButton aria-label="comments" onClick={handleCommentClick(task.id)}>
                                        <CommentIcon />
                                      </IconButton>
                                    </ListItemIcon>
                                  </>
                                )}
                              </ListItem>
                              <br></br>

                              {openSliderForTask === task.id && (
                                <>
                                  <ListItem disablePadding sx={{ bgcolor: "background.paper", borderRadius: 2 }}>
                                    <Grid container spacing={2} alignItems="center">
                                      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Typography variant="h6" gutterBottom>
                                          Minutos Tarefa: {timeSpent[task.id] || 0}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Slider
                                          aria-label="Time spent"
                                          value={timeSpent[task.id] || 0}
                                          onChange={handleTimeChange(task.id)}
                                          valueLabelDisplay="auto"
                                          step={1}
                                          marks
                                          min={0}
                                          max={Math.min((timeSpent[task.id] || 0) + remainingMinutes, workedMinutes)}
                                          style={{ display: openSliderForTask === task.id ? "block" : "none" }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </ListItem>
                                  <br></br>
                                </>
                              )}

                              {openCommentForTask === task.id && (
                                <>
                                  <ListItem disablePadding sx={{ bgcolor: "background.paper", borderRadius: 2 }}>
                                    <Grid container spacing={2} alignItems="center">
                                      <Grid item xs={12}>
                                        <TextField
                                          id={`comment-field-${task.id}`}
                                          label="Notas Tarefa"
                                          variant="outlined"
                                          fullWidth
                                          multiline
                                          value={comments[task.id] || ""}
                                          onChange={(event) => handleCommentChange(task.id, event)}
                                        />
                                      </Grid>
                                    </Grid>
                                  </ListItem>
                                  <br></br>
                                </>
                              )}
                            </React.Fragment>
                          ))}
                        </List>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <Button
                          variant="contained"
                          color={selectedTasks.length > 0 ? "error" : "primary"}
                          onClick={handleFormSubmit}
                          disabled={selectedTasks.length === 0 || remainingMinutes > 0}
                          sx={{ mt: 2, ml: 2 }}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </>
  );
};

export default Activity;
