import { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Typography,
  Container,
  useTheme,
  Grid,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  Avatar,
  Button,
  Divider,
  Chip,
  Alert,
  AlertTitle,
} from "@mui/material";
import { AuthContext } from "../../context/authContext";
import Topbar from "../global/TopBar";
import ElectricBoltOutlinedIcon from "@mui/icons-material/ElectricBoltOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined";
// import TimeDifferenceCalculator from "../global/temp";

const LandingPage = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [error, setError] = useState(null);
  const [bankText, setBankText] = useState("");
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const timerId = useRef(null);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const checkInventory = async () => {
    setError(null);
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `inventory/${currentUser.id}`, config);
      setInventoryData(response.data.data[0]);
      console.log("Inventory:", response.data.data[0]); // Add this console log

      // Check if excess minutes are negative
      if (response.data.data[0].bank < 0) {
        // Calculate absolute value of excess minutes
        const absoluteExcessMinutes = Math.abs(response.data.data[0].bank);

        // Round absolute excess minutes up to nearest multiple of 15
        const roundedExcessMinutes = Math.ceil(absoluteExcessMinutes / 15) * 15;

        const bankTextHours = Math.floor(roundedExcessMinutes / 60);
        const bankTextMinutes = roundedExcessMinutes % 60;

        console.log(bankTextHours, bankTextMinutes);

        // Construct bank text for negative excess minutes
        if (bankTextHours === 1 && bankTextMinutes > 0) {
          setBankText(`${bankTextHours}h e ${bankTextMinutes}m`);
        } else if (bankTextHours === 1 && bankTextMinutes === 0) {
          setBankText(`${bankTextHours} hora`);
        } else if (bankTextHours === 0 && bankTextMinutes > 0) {
          setBankText(`${bankTextMinutes} minutos`);
        } else if (bankTextHours > 0 && bankTextMinutes === 0) {
          setBankText(`${bankTextHours} horas`);
        } else if (bankTextHours > 0 && bankTextMinutes > 0) {
          setBankText(`${bankTextHours}h e ${bankTextMinutes}m`);
        } else if (bankTextHours === 0 && bankTextMinutes === 0) {
          setBankText("0");
        } else {
          setBankText("Não tem banco de horas.");
        }
      } else {
        // Calculate excess minutes in intervals of 15
        const excessMinutesInIntervals = Math.floor(response.data.data[0].bank / 15) * 15;
        const bankTextHours = Math.floor(excessMinutesInIntervals / 60);
        const bankTextMinutes = excessMinutesInIntervals % 60;

        console.log(bankTextHours, bankTextMinutes);

        if (response.data.data[0].bank >= 960 && response.data.data[0].bank !== 1920) {
          clearTimeout(timerId.current);
          setAlert({
            severity: "info",
            title: "Aviso!",
            message: `Tem pelo menos ${bankTextHours} horas no seu banco de horas! É recomendado que usufrua das mesmas antes de chegar ao limite (32 horas).`,
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth", // Optional: adds a smooth scrolling effect
          });
          timerId.current = setTimeout(() => {
            setAlert(null);
          }, 8000);
        } else if (response.data.data[0].bank === 1920) {
          clearTimeout(timerId.current);
          setAlert({
            severity: "warning",
            title: "Aviso!",
            message: `Atingiu o número máximo de horas que pode armazenar no seu banco! Usufrua das mesmas brevemente para voltar a armazenar!`,
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth", // Optional: adds a smooth scrolling effect
          });
          timerId.current = setTimeout(() => {
            setAlert(null);
          }, 8000);
        }

        // Construct bank text for positive excess minutes
        if (bankTextHours === 1 && bankTextMinutes > 0) {
          setBankText(`${bankTextHours}h e ${bankTextMinutes}m`);
        } else if (bankTextHours === 1 && bankTextMinutes === 0) {
          setBankText(`${bankTextHours} hora`);
        } else if (bankTextHours === 0 && bankTextMinutes > 0) {
          setBankText(`${bankTextMinutes} minutos`);
        } else if (bankTextHours > 0 && bankTextMinutes > 0) {
          setBankText(`${bankTextHours}h e ${bankTextMinutes}m`);
        } else if (bankTextHours > 0 && bankTextMinutes === 0) {
          setBankText(`${bankTextHours} horas`);
        } else if (bankTextHours === 0 && bankTextMinutes === 0) {
          setBankText("0");
        } else {
          setBankText("Não tem banco de horas.");
        }
      }
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (currentUser && currentUser.email) {
      checkInventory();
    } else {
      navigate("/login");
    }
  }, [currentUser]);

  if (loading) {
    return (
      <>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }

  return (
    <>
      <Topbar />
      {alert !== null && (
        <Alert variant="filled" severity={alert.severity}>
          <AlertTitle>{alert.title}</AlertTitle>
          {alert.message}
        </Alert>
      )}
      <Container component="main" maxWidth="xs">
        <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
              <br></br>
              <Avatar sx={{ margin: "auto", bgcolor: "secondary.main" }}>
                <ElectricBoltOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h2" fontWeight={"bold"}>
                Bem-vindo(a)!
              </Typography>
              <br></br>
            </Grid>

            <Grid item xs={6}>
              <Grid
                container
                rowSpacing={1}
                sx={{ mb: 4, fontWeight: "bold", textAlign: "center", bgcolor: "background.paper", borderRadius: 2 }}
              >
                <Grid item xs={3}>
                  <Avatar
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: 1,
                      bgcolor: "brown",
                      width: 45,
                      height: 45,
                    }}
                  >
                    <AccountBalanceWalletOutlinedIcon />
                  </Avatar>
                </Grid>
                <Grid item xs={9}>
                  <Typography component="h1" variant="h4" sx={{ fontWeight: "bold", textAlign: "center" }}>
                    Banco Horas
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {inventoryData.bank < 0 ? (
                    <>
                      <Typography component="h1" variant="h4" sx={{ mb: 1, fontWeight: "bold", textAlign: "center", color: "red" }}>
                        -{bankText}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography component="h1" variant="h4" sx={{ mb: 1, fontWeight: "bold", textAlign: "center", color: "green" }}>
                        {bankText}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid
                container
                rowSpacing={1}
                sx={{ mb: 4, fontWeight: "bold", textAlign: "center", bgcolor: "background.paper", borderRadius: 2 }}
              >
                <Grid item xs={3}>
                  <Avatar
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: 1,
                      bgcolor: "brown",
                      width: 45,
                      height: 45,
                    }}
                  >
                    <TokenOutlinedIcon />
                  </Avatar>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    component="h1"
                    variant="h4"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: 2,
                      fontWeight: "bold",
                      textAlign: "center",
                      borderRadius: 2,
                    }}
                  >
                    Tokens
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {inventoryData.tokens <= 0 ? (
                    <>
                      <Typography component="h1" variant="h4" sx={{ mb: 1, fontWeight: "bold", textAlign: "center", color: "red" }}>
                        {inventoryData.tokens}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography component="h1" variant="h4" sx={{ mb: 1, fontWeight: "bold", textAlign: "center", color: "green" }}>
                        {inventoryData.tokens}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ bgcolor: "black", fontWeight: "bold" }} />
              <br></br>
              <Typography component="h1" variant="h5">
                Deseja registar a sua atividade?
              </Typography>
              <br></br>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/activity");
                }}
              >
                Registar Atividade
              </Button>
              <br></br>
              <br></br>
              <Divider sx={{ bgcolor: "black", fontWeight: "bold" }} />
              <br></br>
            </Grid>

            <Grid item xs={12}>
              <Typography component="h1" variant="h5">
                Deseja verificar os seus dados, em forma de gráficos?
              </Typography>
              <br></br>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Dashboard
              </Button>
              <br></br>
              <br></br>
              <Divider sx={{ bgcolor: "black", fontWeight: "bold" }} />
              <br></br>
            </Grid>

            <Grid item xs={12}>
              <Typography component="h1" variant="h5">
                Deseja usufruir do seu banco de horas e tokens disponíveis?
              </Typography>
              <br></br>
              {/* <Button
                disabled
                variant="contained"
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                (Em construção)
              </Button> */}
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/calendar");
                }}
              >
                Calendário
              </Button>
              <br></br>
              <br></br>
              <Divider sx={{ bgcolor: "black", fontWeight: "bold" }} />
              <br></br>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h1" variant="h5">
              Necessita de ajuda para algum tópico da aplicação?
            </Typography>
            <br></br>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/help");
              }}
            >
              Ajuda
            </Button>

            <br></br>
            <br></br>
            <Divider sx={{ bgcolor: "black", fontWeight: "bold" }} />
            <br></br>
          </Grid>
          {/* <div>
            <h1>Time Difference Calculator</h1>
            <TimeDifferenceCalculator />
          </div> */}
        </Box>
      </Container>
    </>
  );
};

export default LandingPage;
